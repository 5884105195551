import { ReactNode, useState } from 'react';
import { useRecoilValue } from 'recoil';
import {
  currentProductTypeState,
  isMobileState,
  userDetailsState,
} from '../../states';
import { PageBanner } from './PageBanner';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Link as MUILink, SxProps, Typography } from '@mui/material';
import {
  dayjs,
  ET,
  showNoLoginUI,
  getTraceOverrideTimeframe,
} from '../../util';
import { TokenTimeframe } from 'config/user';
import { blue } from '@mui/material/colors';
import { ProductType } from 'types';

export type PageProps = {
  children: ReactNode | ReactNode[];
  showLoggedOutBanner?: boolean;
  sx?: SxProps;
};

export const Page = ({
  children,
  showLoggedOutBanner = true,
  sx = {},
}: PageProps) => {
  const userDetails = useRecoilValue(userDetailsState);
  const navigate = useNavigate();
  const isMobile = useRecoilValue(isMobileState);
  const [isOpen, setIsOpen] = useState(true);
  const currentProductType = useRecoilValue(currentProductTypeState);
  const traceOverrideTimeframe: TokenTimeframe | undefined =
    getTraceOverrideTimeframe(dayjs().tz(ET));

  const specialTraceContent =
    currentProductType === ProductType.TRACE && traceOverrideTimeframe != null;

  const dateString = traceOverrideTimeframe?.maxDate.format('M/DD');

  const traceTextContentMap = new Map([
    [
      '10/14',
      <>
        TRACE is only available to non-subscribers through{' '}
        {` ${dateString} \u2014 `}
        <MUILink
          href={`https://spotgamma.com/subscribe-to-spotgamma/`}
          target="_blank"
          onClick={(e) => e.stopPropagation()}
          sx={{ color: blue[300], textDecorationColor: blue[300] }}
        >
          Subscribe to SpotGamma Alpha to access
        </MUILink>
        .&nbsp;{` Have an account? `}
        <Link to={`/login`} style={{ color: blue[300] }}>
          Click here to login.
        </Link>
      </>,
    ],
  ]);

  const loggedOutBanner = userDetails == null &&
    showLoggedOutBanner &&
    !showNoLoginUI() && (
      <PageBanner
        message={
          specialTraceContent ? (
            <Typography
              sx={{
                fontSize: '14px',
                maxWidth: '80%',
                display: 'inline-block',
                color: '#fff',
              }}
            >
              {traceTextContentMap.get(dateString!)}
            </Typography>
          ) : (
            'Viewing the free SpotGamma Preview. Have an account? Click to login.'
          )
        }
        onClick={specialTraceContent ? undefined : () => navigate('/login')}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    );

  return (
    <Box
      sx={{
        height: isMobile
          ? undefined
          : `calc(100vh - ${specialTraceContent && isOpen ? '30px' : '120px'})`,
        ...sx,
      }}
    >
      {loggedOutBanner}
      {children}
    </Box>
  );
};
