import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
  Typography,
  useTheme,
  SxProps,
  Theme,
  Stack,
  alpha,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface SGAccordionProps {
  title: string;
  children: React.ReactNode;
  icon?: JSX.Element;
  expanded: boolean;
  onChange: () => void;
  sx?: SxProps<Theme>;
  paperSx?: SxProps<Theme>;
  hideSummaryWhenExpanded?: boolean; // New optional prop
}

const SGAccordion: React.FC<SGAccordionProps> = ({
  title,
  icon,
  children,
  expanded,
  onChange,
  sx = {},
  paperSx = {},
  hideSummaryWhenExpanded = false, // Default to false
}) => {
  const theme = useTheme();

  return (
    <Accordion
      expanded={expanded}
      onChange={onChange}
      sx={{
        borderRadius: 2,
        '&:before': { display: 'none' },
        display: 'flex',
        flexDirection: 'column',
        ...sx,
      }}
      slotProps={{
        transition: {
          unmountOnExit: true,
        },
      }}
    >
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon sx={{ color: theme.palette.primary.main }} />
        }
        sx={{
          borderRadius: expanded ? 0 : 2,
          paddingX: '16px',
          minHeight:
            hideSummaryWhenExpanded && expanded ? '24px !important' : '48px',
          height:
            hideSummaryWhenExpanded && expanded ? '24px !important' : '48px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start', // Align items to the start
          '& .MuiAccordionSummary-content': {
            display: hideSummaryWhenExpanded && expanded ? 'none' : 'flex',
            flexGrow: 1, // Allow content to grow and occupy space
            margin: hideSummaryWhenExpanded && expanded ? '0px' : '12px 0',
          },
          '& .MuiAccordionSummary-expandIconWrapper': {
            marginLeft: 'auto', // Push the expand icon to the far right
          },
          ':hover': {
            backgroundColor: alpha(theme.palette.primary.main, 0.1),
          },
          transition: 'min-height 0.3s, height 0.3s, padding 0.3s', // Smooth transitions
        }}
      >
        <Stack direction="row" sx={{ gap: 2, alignItems: 'center' }}>
          {icon}
          <Typography
            variant="subtitle1"
            sx={{
              color: theme.palette.primary.main,
              fontWeight: 'bold',
            }}
          >
            {title}
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>
        <Paper
          sx={{
            backgroundColor: 'inherit',
            paddingBottom: '12px',
            paddingX: '16px',
            borderRadius: '0 0 16px 16px',
            width: '100%',
            gap: '24px',
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            ...paperSx,
          }}
        >
          {children}
        </Paper>
      </AccordionDetails>
    </Accordion>
  );
};

export default SGAccordion;
