import { useEffect, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import useFoundersNotes from '../../hooks/foundersNotes/useFoundersNotes';
import { alpha, Theme, useTheme } from '@mui/material/styles';
import { NewTag } from '../../components/shared/Tag';
import RefreshIcon from '@mui/icons-material/Refresh';
import DescriptionIcon from '@mui/icons-material/Description';
import { FoundersNote } from 'components/foundersNotes/FoundersNote';
import {
  FoundersNoteData,
  FoundersNotesIdMap,
} from '../../types/foundersNotes';
import {
  foundersNotesCurrentPageState,
  foundersNotesLatestPageState,
  foundersNotesSelectedNoteState,
  foundersNotesState,
} from '../../states/foundersNotes';
import { parseNoteJson } from '../../util/foundersNote';
import { isAdmin, shadeColor } from '../../util';
import {
  userDetailsState,
  isMobileState,
  screenWidthState,
  timezoneState,
} from '../../states';
import GALogger, { GALoggerCategory } from '../../util/shared/analytics';
import { FoundersNoteWrapper } from '../../components/foundersNotes/FoundersNoteWrapper';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate, useParams } from 'react-router-dom';
import useTooltips from '../../hooks/useTooltips';
import CloseIcon from '@mui/icons-material/Close';
import { InfoButton, Loader } from 'components';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import { FoundersNoteReview } from 'components/foundersNotes/FoundersNoteReview';
import useReviewFoundersNotes from 'hooks/foundersNotes/useReviewFoundersNotes';
import { linkContent } from '../../components/admin/AdminContentList';
import useLog from '../../hooks/useLog';
import { PrintButton } from '../../components/shared/PrintButton';

enum FoundersNoteErrorType {
  Index = 'index',
  SelectedNote = 'selectedNote',
  NoNotes = 'noNotes',
}

enum FoundersNoteLoggerEvents {
  DisplayedNote = 'displayed_note',
  ManuallySelectedNote = 'manually_selected_note',
  NextPage = 'next_page',
  PrevPage = 'prev_page',
  ErrorLoadingNote = 'error_loading_note',
  ErrorLoadingNoteIndex = 'error_loading_note_list',
}

const NotesPerPage = 15;

export const FoundersNotes = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  dayjs.extend(localeData);

  const [notes, setNotes] = useRecoilState<FoundersNotesIdMap | null>(
    foundersNotesState,
  );
  const [notesLatestPage, setNotesLatestPage] = useRecoilState<
    FoundersNoteData[] | null
  >(foundersNotesLatestPageState);
  const [selectedNote, setSelectedNote] =
    useRecoilState<FoundersNoteData | null>(foundersNotesSelectedNoteState);
  const [loading, setLoading] = useState(false);
  const [loadingNotesList, setLoadingNotesList] = useState(false);
  const [errored, setErrored] = useState<FoundersNoteErrorType | null>(null);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [printTheme, setPrintTheme] = useState<Theme | undefined>();

  const [page, setPage] = useRecoilState(foundersNotesCurrentPageState);
  const isMobile = useRecoilValue(isMobileState);
  const screenWidth = useRecoilValue(screenWidthState);
  const [showingDrawer, setShowingDrawer] = useState(screenWidth >= 1000);
  const [selectedMonth, setSelectedMonth] = useState(dayjs().month());
  const [selectedYear, setSelectedYear] = useState(dayjs().year());
  const noteRef = useRef<HTMLDivElement>(null);
  const userDetails = useRecoilValue(userDetailsState);
  const currentTimezone = useRecoilValue(timezoneState);
  const { logError } = useLog('FoundersNotes');

  const { onOpenReview } = useReviewFoundersNotes();

  const {
    getFoundersNotesIndex,
    getFoundersNoteWithId,
    getFoundersNoteWithPreviewKey,
  } = useFoundersNotes();
  const { loadTooltips } = useTooltips();

  const logger = new GALogger(GALoggerCategory.FoundersNotes);

  const { id, previewKey } = useParams();

  const showSidebar = previewKey == null && userDetails != null;
  const yearCount = dayjs().year() - 2020 + 1;
  const years = [...Array(yearCount)].map((_, i) => `${2020 + i}`);
  const months = dayjs.months();

  const hasNoteIdParam = id != null || previewKey != null;

  async function fetchIndexData() {
    try {
      if (!loadingNotesList || errored != null) {
        if (selectedNote == null) {
          setLoading(true);
        }
        setLoadingNotesList(true);
        const [response, _] = await Promise.all([
          getFoundersNotesIndex(
            page,
            NotesPerPage,
            selectedMonth,
            selectedYear,
          ),
          loadTooltips(),
        ]);
        if (response.error != null) {
          setErrored(FoundersNoteErrorType.Index);
          return setNotesLatestPage(null);
        }

        let newNotes: FoundersNoteData[] = [];
        let combinedNotes: FoundersNotesIdMap = {};
        if (notes != null) {
          Object.keys(notes!).forEach(
            (key) => (combinedNotes[parseInt(key)] = notes![parseInt(key)]),
          );
        }

        for (let i = 0; i < response.data.length; i++) {
          const newNote = parseNoteJson(response.data[i], currentTimezone);
          newNotes.push(newNote);
          if (combinedNotes[newNote.key] == null) {
            combinedNotes[newNote.key] = newNote;
          }
        }

        setNotesLatestPage(newNotes);
        setHasNextPage(response.hasNextPage);
        setNotes(combinedNotes);
        if (selectedNote == null) {
          setLoading(false);
        }
        if (newNotes.length === 0) {
          setErrored(FoundersNoteErrorType.NoNotes);
        } else {
          if (!hasNoteIdParam && newNotes[0].html && selectedNote == null) {
            setSelectedNote(newNotes[0]);
            logger.logEvent(FoundersNoteLoggerEvents.DisplayedNote, {
              label: newNotes[0].title,
            });
          }
          setErrored(null);
        }
      }
    } catch (err) {
      logError(err, 'fetchIndexData');
      logger.logEvent(FoundersNoteLoggerEvents.ErrorLoadingNoteIndex, {
        label: err?.toString() ?? 'Unknown error',
      });
      setErrored(FoundersNoteErrorType.Index);
      setLoading(false);
    } finally {
      setLoadingNotesList(false);
    }
  }

  useEffect(() => {
    if (hasNoteIdParam) {
      loadSelectedNote(id ?? previewKey!);
    }
  }, [id]);

  useEffect(() => {
    if (showSidebar) {
      fetchIndexData();
    }
  }, [page, selectedMonth, selectedYear]);

  const loadSelectedNote = async (noteId: string) => {
    if (noteId == null) {
      return;
    }

    const note = (notes ?? {})[noteId];
    // change url in navbar without actually navigating
    if (previewKey == null) {
      navigate(
        `/foundersNotes/${encodeURIComponent(
          noteId.replace('founders-notes/', ''),
        )}`,
        { replace: true },
      );
    }

    if (note?.html != null) {
      setSelectedNote(note);
      logger.logEvent(FoundersNoteLoggerEvents.DisplayedNote, {
        label: note.title,
      });
    } else {
      setLoading(true);
      try {
        const [{ data }, _] = await Promise.all([
          previewKey != null
            ? getFoundersNoteWithPreviewKey(noteId)
            : getFoundersNoteWithId(noteId),
          loadTooltips(),
        ]);

        if (data == null) {
          return setErrored(FoundersNoteErrorType.SelectedNote);
        }
        let newNote = parseNoteJson(data, currentTimezone);
        let combinedNotes: FoundersNotesIdMap = { ...(notes ?? {}) };
        combinedNotes[noteId] = newNote;

        setNotes(combinedNotes);
        setErrored(null);
        setSelectedNote(newNote);
        logger.logEvent(FoundersNoteLoggerEvents.DisplayedNote, {
          label: data.title ?? '',
        });
      } catch (err) {
        logError(err, 'loadSelectedNote');
        logger.logEvent(FoundersNoteLoggerEvents.ErrorLoadingNote, {
          label: err?.toString() ?? 'Unknown error',
        });
        setSelectedNote(note); // store that we were trying to load this note for when we retry
        setErrored(FoundersNoteErrorType.SelectedNote);
      } finally {
        setLoading(false);
      }
    }
  };

  const retryFetch = () => {
    if (errored === FoundersNoteErrorType.Index) {
      fetchIndexData();
    } else if (errored === FoundersNoteErrorType.SelectedNote) {
      loadSelectedNote(selectedNote!.key);
    }

    setErrored(null);
  };

  const refreshSidebar = () => {
    const month = dayjs().month();
    const year = dayjs().year();
    if (month !== selectedMonth || year !== selectedYear) {
      // setting these will then trigger a refresh because of the useEffect
      setSelectedMonth(month);
      setSelectedYear(year);
    } else {
      fetchIndexData();
    }
  };

  const refreshSubheader = (
    <ListSubheader
      sx={{
        '&:hover': {
          cursor: 'pointer',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        <span onClick={() => refreshSidebar()} style={{ order: 1 }}>
          Refresh{' '}
          <RefreshIcon fontSize={'small'} sx={{ marginBottom: '-3px' }} />
        </span>
        <Box
          style={{
            order: isMobile ? 3 : 2,
            margin: 'auto',
          }}
        >
          <Select
            value={selectedMonth}
            onChange={(e) => {
              setSelectedMonth(parseInt(e.target.value as string));
              setPage(1);
            }}
            autoWidth
            sx={{
              height: '30px',
              '& .MuiSvgIcon-root': {
                color: theme.palette.primary.main,
              },
            }}
          >
            {months
              .slice(
                0,
                selectedYear === dayjs().year()
                  ? dayjs().month() + 1
                  : months.length,
              )
              .map((month, i) => {
                return (
                  <MenuItem value={i}>
                    <Typography color={theme.palette.text.secondary}>
                      {month}
                    </Typography>
                  </MenuItem>
                );
              })}
          </Select>
          <Select
            value={selectedYear}
            onChange={(e) => {
              const newYear = parseInt(e.target.value as string);
              if (
                newYear === dayjs().year() &&
                selectedMonth > dayjs().month()
              ) {
                setSelectedMonth(dayjs().month());
              }
              setSelectedYear(newYear);
              setPage(1);
            }}
            sx={{
              height: '30px',
              '& .MuiSvgIcon-root': {
                color: theme.palette.primary.main,
              },
            }}
          >
            {years.map((year) => {
              return (
                <MenuItem value={year}>
                  <Typography color={theme.palette.text.secondary}>
                    {year}
                  </Typography>
                </MenuItem>
              );
            })}
          </Select>
        </Box>
        <span
          onClick={() => toggleShowingDrawer()}
          style={{ order: isMobile ? 2 : 3 }}
        >
          Close
          <CloseIcon fontSize={'small'} sx={{ marginBottom: '-4px' }} />
        </span>
      </Box>
    </ListSubheader>
  );

  // label the latest founders notes and anything that comes before it as 'new'
  const firstFoundersNoteIndex =
    page === 1
      ? (notesLatestPage ?? []).findIndex((note) =>
          note.title.includes("Founder's Note"),
        )
      : -1;

  const notesList = (
    <Loader isLoading={loadingNotesList}>
      <List subheader={refreshSubheader}>
        {(notesLatestPage || []).map(
          (note: FoundersNoteData, index: number) => {
            return (
              <ListItemButton
                selected={selectedNote != null && selectedNote.key === note.key}
                onClick={() => {
                  logger.logEvent(
                    FoundersNoteLoggerEvents.ManuallySelectedNote,
                    {
                      label: note.title,
                    },
                  );
                  if (isMobile) {
                    setShowingDrawer(false);
                  }
                  loadSelectedNote(note.key);
                }}
                key={`list-${note.key}`}
              >
                <ListItemAvatar sx={{ marginRight: '-15px' }}>
                  <ListItemIcon>
                    <DescriptionIcon fontSize="medium" color="primary" />
                  </ListItemIcon>
                </ListItemAvatar>
                <ListItemText
                  primary={note.title}
                  secondary={note.dateDisplayString}
                />
                {index <= firstFoundersNoteIndex && <NewTag />}
              </ListItemButton>
            );
          },
        )}
      </List>
    </Loader>
  );

  const title = selectedNote?.title;
  const loadingOrErrored = loading ? (
    <Loader isLoading={loading} />
  ) : errored ? (
    <a onClick={() => retryFetch()}>
      {errored === FoundersNoteErrorType.NoNotes
        ? 'There are no notes available for this month/year.'
        : 'There was an error fetching this page.'}{' '}
      Click here to retry.
    </a>
  ) : null;

  const toggleShowingDrawer = () => {
    setShowingDrawer(!showingDrawer);
  };

  const nextPage = () => {
    if (!hasNextPage) {
      return;
    }
    const newPage = page + 1;
    logger.logEvent(FoundersNoteLoggerEvents.NextPage, { value: newPage });
    setPage(newPage);
  };

  const previousPage = () => {
    if (page <= 1) {
      return;
    }
    const newPage = page - 1;
    logger.logEvent(FoundersNoteLoggerEvents.PrevPage, { value: newPage });
    setPage(newPage);
    setHasNextPage(true);
  };

  const notesListComponent = showingDrawer && (
    <Box
      style={{
        ...(isMobile
          ? { width: '250px' }
          : {
              minWidth: '300px',
              width: '400px',
            }),
        paddingBottom: '70px', // support button
        height: 'calc(100%-50px)',
        backgroundColor: `${theme.palette.background.paper}`,
        color: `${theme.palette.text.primary}`,
        boxShadow: theme.palette.shadows.default,
        borderLeft: `solid 1px ${alpha(theme.palette.text.primary, 0.15)}`,
        overflow: 'auto',
      }}
    >
      {notesList}
      {notesLatestPage != null &&
        !loadingNotesList &&
        errored !== FoundersNoteErrorType.NoNotes && (
          <Box sx={{ justifyContent: 'center', display: 'flex' }}>
            <IconButton
              onClick={previousPage}
              color="primary"
              disabled={page <= 1}
            >
              <ArrowBackIcon fontSize="small" />
            </IconButton>
            <Typography
              color="primary"
              sx={{
                alignSelf: 'center',
                padding: '20px',
                fontSize: '16px',
                fontWeight: 900,
              }}
            >
              {page}
            </Typography>
            <IconButton
              onClick={nextPage}
              color="primary"
              disabled={!hasNextPage}
            >
              <ArrowForwardIcon fontSize="small" />
            </IconButton>
          </Box>
        )}
    </Box>
  );

  const sidebar = isMobile ? (
    <Drawer
      variant="temporary"
      anchor="right"
      open={showingDrawer}
      onClose={() => setShowingDrawer(false)}
    >
      {notesListComponent}
    </Drawer>
  ) : (
    notesListComponent
  );

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'space-between',
        flexDirection: 'column',
      }}
    >
      {
        <Box
          sx={{
            paddingRight: 5,
            paddingBottom: 5,
            borderBottom: `1px solid ${shadeColor(
              theme.palette.background.paper,
              -8,
            )}`,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: '4px',
          }}
        >
          {!showingDrawer && userDetails != null && (
            <Button
              variant="outlined"
              sx={{
                textTransform: 'none',
                fontSize: '12px',
                height: '30px',
                whiteSpace: 'nowrap',
              }}
              onClick={toggleShowingDrawer}
            >
              View all notes
            </Button>
          )}
          <Box
            sx={{
              justifySelf: 'flex-end',
              gap: '4px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            {isAdmin(userDetails) && (
              <Button
                variant="outlined"
                sx={{
                  textTransform: 'none',
                  fontSize: '12px',
                  height: '30px',
                  whiteSpace: 'nowrap',
                }}
                onClick={async () => {
                  await linkContent(
                    selectedNote?.title,
                    selectedNote?.freePreviewKey,
                  );
                }}
              >
                (Admin Only) Free link
              </Button>
            )}
            {userDetails != null && (
              <Button
                variant="outlined"
                sx={{
                  textTransform: 'none',
                  fontSize: '12px',
                  height: '30px',
                  whiteSpace: 'nowrap',
                }}
                onClick={async () => await onOpenReview()}
              >
                Review
              </Button>
            )}
            {!isMobile && (
              <PrintButton
                content={noteRef.current}
                onPrintTheme={setPrintTheme}
              />
            )}
            <InfoButton
              key="founders-note-info"
              articleKey="founders-note-info"
            />
          </Box>
        </Box>
      }

      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
          justifyContent: 'space-between',
          flexDirection: 'row',
        }}
      >
        <FoundersNoteWrapper ref={noteRef} theme={printTheme}>
          {loadingOrErrored ?? (
            <Box style={{ overflow: 'auto' }}>
              <FoundersNote
                html={
                  selectedNote && selectedNote.html ? selectedNote.html : ''
                }
                title={title}
                fromWordpress={selectedNote?.wordpress ?? false}
                id={selectedNote?.key}
                printTheme={printTheme}
                date={selectedNote?.date}
              />
            </Box>
          )}
        </FoundersNoteWrapper>
        {showSidebar && sidebar}
      </Box>
      <FoundersNoteReview selectedNote={selectedNote} />
    </Box>
  );
};
