import { atom, selector } from 'recoil';
import { screenWidthState } from './shared';
import {
  Equity,
  FuturesSnapshotData,
  ImpactType,
  Quadrant,
  QuadrantId,
  ProcessedTooltipDefinition,
} from 'types';
import { HOME_DEFAULT_QUADRANTS_MAP } from 'config/home';
import { userSettingsState } from 'states';
import {
  getDefaultLabelForContent,
  getDefaultSymForContent,
  getDefaultSymOptionsForContent,
} from 'util/home';
import { mergeQuadrantsWithDefaults } from 'util/shared';

export const earningsCalendarState = atom<any>({
  key: 'home-earningsCalendarState',
  default: null,
});

export const homeShowInListState = selector<boolean>({
  key: 'home-showInListState',
  get: ({ get }) => {
    const screenWidth = get(screenWidthState);
    return screenWidth < 1000;
  },
});

export const sgTooltipsState = atom<ProcessedTooltipDefinition[]>({
  key: 'home-sgTooltipsState',
  default: [],
});

export const macroCalendarCountriesSettingState = atom<string[]>({
  key: 'home-macroCalendarCountriesSettingState',
  default: ['US'],
});

export const macroCalendarCountryOptionsState = atom<string[]>({
  key: 'home-macroCalendarCountryOptionsState',
  default: [],
});

export const macroCalendarImpactsSettingState = atom<ImpactType[]>({
  key: 'home-macroCalendarImpactSettingState',
  default: [ImpactType.HIGH],
});

export const equityScannersDataState = atom<Equity[]>({
  key: 'home-equityScannersDataState',
  default: [],
});

export const selectedSymLevelsState = atom<string>({
  key: 'home-selectedSymLevelsState',
  default: 'SPX',
});

export const homeEditModalOpenState = atom<boolean>({
  key: 'home-homeEditModalOpenState',
  default: false,
});

// This state returns user available quadrants
export const homeQuadrantsState = selector<Map<QuadrantId, Quadrant>>({
  key: 'home-homeQuadrantsState',
  get: ({ get }) => {
    const settings = get(userSettingsState);
    if (settings?.homeQuadrants != null) {
      const quadrantsMap = new Map<QuadrantId, Quadrant>(
        Object.entries(settings.homeQuadrants).map(([key, value]) => [
          key as QuadrantId,
          value,
        ]),
      );
      // this merge is necessary in order to show the most "up to date" settings even while user has an older one,
      // once user clicks save again, their older settings becomes up to date
      return mergeQuadrantsWithDefaults(
        quadrantsMap,
        getDefaultSymForContent,
        getDefaultSymOptionsForContent,
        getDefaultLabelForContent,
      );
    }
    return HOME_DEFAULT_QUADRANTS_MAP;
  },
});

export const activeQuadrantTabsState = atom<
  Map<QuadrantId, string | undefined>
>({
  key: 'home-activeQuadrantTabsState',
  default: new Map(),
});

export const futuresSnapshotState = atom<FuturesSnapshotData[]>({
  key: 'futures-snapshot-state',
  default: [],
});

export const futuresVisibleState = selector({
  key: 'futures-visible-state',
  get: ({ get }) => {
    const settings = get(userSettingsState);
    return !settings?.tickersHidden;
  },
});
