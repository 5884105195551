import {
  Badge,
  Box,
  Checkbox,
  FormControl,
  IconButton,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tab,
  Typography,
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  hiroSymbolsState,
  isMobileState,
  sidebarExpandedState,
  stocksState_SUBSCRIBE_TO_POLLING_RENDER,
  trendingState,
  unseenAlertCountState,
  userSettingsState,
  watchlistsState,
  workerState,
} from '../../states';
import { useTheme } from '@mui/material/styles';
import { StockTrendingCard } from '../shared/StockTrendingCard';
import {
  ProductType,
  RawTimeseriesData,
  RawTrending,
  SubLevel,
  TimeSeriesData,
  TrendingData,
  TrendingElement,
} from '../../types';
import useTrending from '../../hooks/hiro/useTrending';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import {
  getAuthHeader,
  getSubscriptionLevel,
  groupTrendingData,
  isBloomberg,
} from '../../util';
import { HIRO_UPSELL, TRENDING_LIMIT } from '../../config';
import { userDetailsState, selectedWatchlistState } from '../../states/auth';
import EditIcon from '@mui/icons-material/Edit';
import useTimeSeries from '../../hooks/equityhub/useTimeSeries';
import { InfoButton } from '../shared/InfoButton';
import { Loader } from '../shared/Loader';
import { mapFromObj, Tabs } from '../shared/Tabs';
import useAuth from '../../hooks/auth/useAuth';
import { UpsellModal } from '../shared/Upsell';
import { AlertsTabBody } from '../shared/AlertTabBody';
import { EditWatchlists } from './EditWatchlists';
import useToast from '../../hooks/useToast';
import useUserDetails from '../../hooks/user/useUserDetails';
import useWorksheets from '../../hooks/bloomberg/api/useWorksheets';
import { SGTooltip } from '../core';

interface WatchlistAndTrendingProps {
  type: ProductType;
}

export const WatchlistAndTrendingScrollContainer = ({
  children,
}: {
  children: ReactNode;
}) => {
  const isMobile = useRecoilValue(isMobileState);
  return (
    <Box
      mt={4}
      display="flex"
      height="100%"
      width="100%"
      flexDirection={'column'}
      className="hiroWatchlistAndTrendingContainer"
      sx={{
        ...(isMobile
          ? { overflowX: 'auto', minHeight: '475px' }
          : { overflow: 'auto' }),
      }}
    >
      {children}
    </Box>
  );
};
export const CardLayout = ({ children }: { children: ReactNode }) => {
  const theme = useTheme();
  const isMobile = useRecoilValue(isMobileState);
  const tapToScroll = isMobile ? (
    <Typography
      sx={{
        width: '100%',
        textAlign: 'center',
        padding: '5px',
        fontSize: '12px',
        overflowY: 'hidden',
        display: 'inline-block',
      }}
      color={theme.palette.text.secondary}
    >
      {'<-'} Tap then scroll {'->'}
    </Typography>
  ) : null;

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      {tapToScroll}
      <Box
        sx={{
          transition: '0.5s',
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          flexWrap: 'wrap',
          gap: theme.spacing(3),
          flex: 1,
          columns: 2,
          marginTop: '3px',
          ...(isMobile
            ? {
                maxHeight: '350px',
                overflowX: 'auto !important',
                padding: '10px',
              }
            : {}),
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export const WatchlistAndTrending = ({ type }: WatchlistAndTrendingProps) => {
  const TRENDING_POLL_INTERVAL = 60_000;

  const stocks = useRecoilValue(stocksState_SUBSCRIBE_TO_POLLING_RENDER);
  const [trending, setTrending] = useRecoilState(trendingState);
  const [trendingHiro, setTrendingHiro] = useState<RawTrending>({});
  const [fetchingError, setFetchingError] = useState(false);
  const combinedWatchlist = useRecoilValue(selectedWatchlistState);
  const [hiroWatchlistDisplayData, setHiroWatchlistDisplayData] = useState<
    TrendingData[]
  >([]);
  const [equityhubWatchlistData, setEquityhubWatchlistData] = useState<
    TimeSeriesData[]
  >([]);
  const [watchlistTrend, setWatchlistTrend] = useState<RawTrending>({});
  const sidebarExpanded = useRecoilValue(sidebarExpandedState);

  const theme = useTheme();
  const { getTrending, getTrendingSparkLines } = useTrending();
  const [selectedTab, setSelectedTab] = useState(
    type === ProductType.HIRO ? 'trending' : 'watchlist',
  );

  const [trendingLoading, setTrendingLoading] = useState(false);
  const [skipNextWatchlistLoad, setSkipNextWatchlistLoad] = useState(false);
  const [watchlistLoading, setWatchlistLoading] = useState(false);
  const { getTimeSeries } = useTimeSeries();
  const hiroSyms = useRecoilValue(hiroSymbolsState);
  const hiroWatchlist = useMemo(() => {
    // TODO: Should we be allowing capitalized watchlist syms that won't work on a fetch to be in watchlist?
    const normedSyms = new Set([...hiroSyms].map((s) => s.toUpperCase()));
    return combinedWatchlist.filter((s) => normedSyms.has(s.toUpperCase()));
  }, [combinedWatchlist, hiroSyms]);
  const isMobile = useRecoilValue(isMobileState);
  const worker = useRecoilValue(workerState);
  const [upsellOpen, setUpsellOpen] = useState<boolean>(false);
  const { productsWithAccess } = useAuth();
  const hasHiroAccess = productsWithAccess.has(ProductType.HIRO);
  const alertCount = useRecoilValue(unseenAlertCountState);
  const { saveSgSettings } = useUserDetails();
  const userDetails = useRecoilValue(userDetailsState);
  const upgradeToHiro =
    !hasHiroAccess &&
    [SubLevel.PRO, SubLevel.STANDARD].includes(
      getSubscriptionLevel(userDetails),
    );
  const [showEditWatchlistModal, setShowEditWatchlistModal] = useState(false);
  const watchlistsArr = useRecoilValue(watchlistsState);
  const settings = useRecoilValue(userSettingsState);
  const hasWatchlistAccess = productsWithAccess.has(type);

  const watchlists = watchlistsArr ?? [];
  const watchlistIds = new Set(watchlists.map((w) => w.id));
  const selectedWatchlistIds =
    (settings.selectedWatchlistIds ?? []).filter((id) =>
      watchlistIds.has(id),
    ) ?? watchlistIds.values();

  const { fetchWorksheets } = useWorksheets();

  const setSelectedWatchlistIds = (watchlistIds: number[]) => {
    saveSgSettings({ selectedWatchlistIds: watchlistIds });
  };

  const refreshBbgWatchlists = async () => {
    setWatchlistLoading(true);
    await fetchWorksheets();
    setWatchlistLoading(false);
  };

  useEffect(() => {
    // only for bloomberg:
    // on first load, we're using the watchlists the user last saved to our servers
    // when they open the app again, fetch the latest worksheets they have as they may have changed
    // and upload them + set their watchlists to match these new worksheets
    if (isBloomberg()) {
      refreshBbgWatchlists();
    }
  }, []);

  const { openToast } = useToast();

  useEffect(() => {
    let unmounted = false;
    let workerId: number | null = null;

    const onMessage = (msg: any) => {
      if (unmounted) {
        return unsubscribe();
      } else if (msg?.data?.id !== workerId || msg?.data?.response == null) {
        return;
      }

      const sparklinesData = msg?.data?.response.sparklines;
      const trendingSyms = msg?.data?.response.trending;

      const groupedData = Object.fromEntries(
        groupTrendingData(sparklinesData, stocks).map((d) => [d.instrument, d]),
      );

      const newTrendingData = trendingSyms.map((d: TrendingData) => ({
        ...d,
        ...groupedData[d.instrument],
      }));
      const newWatchlistData = hiroWatchlist.map(
        (sym: string) => groupedData[sym],
      );
      if (newTrendingData.length > 0) {
        setTrending(newTrendingData as TrendingData[]);
      }
      if (newWatchlistData.length > 0) {
        setHiroWatchlistDisplayData(newWatchlistData as TrendingData[]);
      }
    };

    const unsubscribe = () => {
      if (workerId != null) {
        worker.clearPoller(workerId);
        worker.removeEventListener('message', onMessage);
        workerId = null;
      }
      unmounted = true;
    };

    async function setupPolling() {
      // TODO: revisit how we poll here
      const HALF_HOUR = 720;
      if (workerId != null) {
        unsubscribe();
      }

      // Catch a recoil state race where this isn't set yet
      if (worker == null) {
        return;
      }
      worker.setAuthHeader(getAuthHeader());
      workerId = await worker.setPollerForHiroTrendingAndWatchlist(
        'v3/trending?&interval=30',
        `v4/latestHiro?all=1&limit=${HALF_HOUR}`,
        // on first load hiroWatchlist may be blank but combinedWatchlist isnt
        hiroWatchlist.length > 0 ? hiroWatchlist : combinedWatchlist,
        TRENDING_POLL_INTERVAL,
      );
      worker.addEventListener('message', onMessage);

      unmounted = false;
    }

    if (type !== ProductType.HIRO) {
      return;
    }
    if (hasHiroAccess) {
      setupPolling();
    }

    return unsubscribe;
  }, [
    combinedWatchlist,
    hiroWatchlist,
    setTrending,
    setHiroWatchlistDisplayData,
    type,
    worker,
    userDetails,
  ]);

  useEffect(() => {
    async function fetchTrendingData() {
      if (type !== ProductType.HIRO || !sidebarExpanded) {
        return;
      }
      setTrendingLoading(true);
      const newTrendingData: TrendingElement[] = await getTrending();
      if (newTrendingData?.length > 0) {
        const trendingSyms: string[] = newTrendingData.map((d) => d.instrument);
        const sparkData: RawTrending = await getTrendingSparkLines(
          trendingSyms,
        );
        setTrendingHiro(sparkData);
      }
      setTrendingLoading(false);
    }
    if (hasHiroAccess) {
      fetchTrendingData();
    }
  }, [getTrending, getTrendingSparkLines, setTrending, sidebarExpanded, type]);

  useEffect(() => {
    async function fetchHiroWatchlistData() {
      if (type !== ProductType.HIRO || !sidebarExpanded) {
        return;
      }
      if (skipNextWatchlistLoad) {
        setSkipNextWatchlistLoad(false);
        return;
      }
      setWatchlistLoading(true);
      let sparkEntries = {};
      for (var i = 0; i < hiroWatchlist.length; i += TRENDING_LIMIT) {
        const end = i + TRENDING_LIMIT;
        const batchWatchlist = (hiroWatchlist || []).slice(i, end);
        const sparkData: RawTrending = await getTrendingSparkLines(
          batchWatchlist,
        );
        sparkEntries = { ...sparkEntries, ...sparkData };
        setWatchlistTrend(sparkEntries);
      }
      setFetchingError(false);
      setWatchlistLoading(false);
    }
    if (hasHiroAccess) {
      fetchHiroWatchlistData();
    }
  }, [
    getTrendingSparkLines,
    hiroWatchlist,
    setHiroWatchlistDisplayData,
    sidebarExpanded,
    skipNextWatchlistLoad,
    type,
  ]);

  useEffect(() => {
    setTrending(groupTrendingData(trendingHiro, stocks));
  }, [trendingHiro, setTrending, stocks]);

  useEffect(() => {
    setHiroWatchlistDisplayData(groupTrendingData(watchlistTrend, stocks));
  }, [setHiroWatchlistDisplayData, stocks, watchlistTrend]);

  useEffect(() => {
    async function fetchEhWatchlistData() {
      if (type === ProductType.HIRO || !sidebarExpanded) {
        return;
      }
      if (skipNextWatchlistLoad) {
        setSkipNextWatchlistLoad(false);
        return;
      }
      if (combinedWatchlist.length === 0) {
        return;
      }
      setWatchlistLoading(true);
      const data = await getTimeSeries(combinedWatchlist, true);

      // TODO: Handle failures on individual symbols?
      if (data?.error != null) {
        setFetchingError(true);
        setWatchlistLoading(false);
        return;
      }

      const groupedData = Object.entries(data as RawTimeseriesData)
        .filter(
          ([sym, entry]) =>
            entry.status !== 'error' && sym === entry.meta.symbol,
        )
        .map(([symbol, entry]) => {
          const values = entry.values?.map((v: any) => Number(v?.close)) ?? [];
          const delta = values[values.length - 1] - values[0];
          return {
            symbol,
            price: values[values.length - 1] ?? null,
            priceDelta: isNaN(delta) ? null : delta,
            values,
          };
        });
      setEquityhubWatchlistData(groupedData as TimeSeriesData[]);
      setFetchingError(false);
      setWatchlistLoading(false);
    }
    fetchEhWatchlistData();
  }, [
    getTimeSeries,
    setEquityhubWatchlistData,
    combinedWatchlist,
    sidebarExpanded,
    skipNextWatchlistLoad,
    type,
  ]);

  let cards;
  if (type === ProductType.HIRO) {
    // pre-open, trending watchlist may be empty as we dont have sparklines data for watchlist syms yet
    // so use hiroWatchlist as source of truth and conditionally render data if we have it
    cards = hiroWatchlist.map((sym) => {
      const data = hiroWatchlistDisplayData.find((d) => d?.instrument === sym);

      return (
        <StockTrendingCard
          key={`watchlist-${sym}`}
          instrument={sym}
          hiroData={data != null ? (data as TrendingData) : null}
          type={type}
        />
      );
    });
  } else {
    cards = equityhubWatchlistData.map((trendingSymbol) => (
      <StockTrendingCard
        key={`watchlist-eh-${trendingSymbol.symbol}`}
        ehData={trendingSymbol as TimeSeriesData}
        instrument={trendingSymbol.symbol}
        type={type}
      />
    ));
  }

  // Only show info button if EquityHub or HIRO Watchlist Tab
  const infoType = type === ProductType.HIRO ? 'HIRO' : 'Equity Hub';
  const ctrlProps =
    selectedTab === 'trending'
      ? [
          <InfoButton
            key={`${infoType}-trending-info`}
            articleKey={`${infoType}-trending`}
          />,
        ]
      : [
          <InfoButton
            key={`${infoType}-watchlist-info`}
            articleKey={`${infoType}-watchlist`}
          />,
        ];
  return (
    <WatchlistAndTrendingScrollContainer>
      <Box
        className="watchlistAndTrending"
        sx={{
          transition: '0.5s',
          display: 'flex',
          flexGrow: 1,
          flexDirection: 'column',
          ...(isMobile
            ? { overflowX: 'auto', overflowY: 'hidden', height: '100%' }
            : {}),
        }}
      >
        <TabContext value={selectedTab}>
          <Tabs
            options={mapFromObj({
              trending: type === ProductType.HIRO ? 'Trending' : null,
              watchlist: isBloomberg() ? 'Worksheets' : 'Watchlists',
              alerts:
                type === ProductType.HIRO ? (
                  <Tab
                    key="alerts"
                    value="alerts"
                    label={
                      alertCount > 0 ? (
                        <Badge
                          badgeContent={alertCount}
                          color="error"
                          sx={{
                            textTransform: 'none',
                            fontSize: '14px',
                          }}
                        >
                          Alerts
                        </Badge>
                      ) : (
                        'Alerts'
                      )
                    }
                    sx={{ padding: theme.spacing(3), fontSize: '12px' }}
                  />
                ) : null,
            })}
            onChange={(_evt, newTab: string) => setSelectedTab(newTab)}
            controlProps={{ options: ctrlProps }}
            wrapperClassName="hiroWatchlistAndTrendingTabs"
          />
          {type === ProductType.HIRO && ( // trending, show in Hiro only
            <TabPanel value="trending" sx={{ maxHeight: 'calc(100% - 50px)' }}>
              <Loader isLoading={trendingLoading}>
                {trending.length === 0 && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      p: theme.spacing(5),
                      background: theme.palette.background.paper,
                      boxShadow: theme.palette.shadows.paperBoxShadow,
                    }}
                  >
                    <Typography variant="h3" gutterBottom color="text.primary">
                      {!hasHiroAccess ? (
                        <a
                          onClick={() => {
                            setUpsellOpen(true);
                          }}
                        >
                          Upgrade for access to trending symbols
                        </a>
                      ) : (
                        'No symbols are trending currently.'
                      )}
                    </Typography>
                  </Box>
                )}
                {trending.length > 0 && (
                  <CardLayout>
                    {trending.map((data) => (
                      <StockTrendingCard
                        key={`trending-${data.instrument}`}
                        instrument={data.instrument}
                        hiroData={data as TrendingData}
                        type={type}
                      />
                    ))}
                  </CardLayout>
                )}
              </Loader>
            </TabPanel>
          )}
          <TabPanel value="watchlist" sx={{ maxHeight: 'calc(100% - 50px)' }}>
            {hasWatchlistAccess && (
              <Box
                width="100%"
                display="flex"
                gap="10px"
                justifyContent="right"
                margin="10px 0px"
              >
                <FormControl sx={{ flexGrow: 1, textAlign: 'center' }}>
                  <Select
                    multiple
                    value={selectedWatchlistIds}
                    displayEmpty
                    style={{
                      border: `0.5px solid ${theme.palette.text.secondary}`,
                    }}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return watchlists.length > 0
                          ? `Click to select watchlists`
                          : `Must add a watchlist first.`;
                      }
                      return `Selected ${selected.length} of ${
                        watchlists.length
                      } watchlist${watchlists.length > 1 ? 's' : ''}`;
                    }}
                    onChange={(
                      event: SelectChangeEvent<typeof selectedWatchlistIds>,
                    ) => {
                      const value = event.target.value;
                      setSelectedWatchlistIds(
                        (typeof value === 'string'
                          ? value.split(',').map((s) => parseInt(s))
                          : value) as number[],
                      );
                    }}
                  >
                    {watchlists.map((watchlist) => {
                      if (watchlist.id == null) {
                        return null;
                      }
                      return (
                        <MenuItem value={watchlist.id} key={watchlist.id}>
                          <Checkbox
                            checked={
                              selectedWatchlistIds.indexOf(watchlist.id!) > -1
                            }
                          />
                          <ListItemText>{watchlist.name}</ListItemText>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                {isBloomberg() ? (
                  <IconButton
                    onClick={refreshBbgWatchlists}
                    color="primary"
                    aria-label="refresh worksheets"
                    sx={{ width: '40px', height: '40px' }}
                  >
                    <SGTooltip title={'Fetch latest worksheets'}>
                      <RefreshIcon />
                    </SGTooltip>
                  </IconButton>
                ) : (
                  <IconButton
                    aria-label="edit watchlist"
                    onClick={(event) => {
                      setShowEditWatchlistModal(true);
                    }}
                    color="primary"
                    sx={{ width: '40px', height: '40px' }}
                  >
                    <EditIcon />
                  </IconButton>
                )}
              </Box>
            )}

            {fetchingError ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  p: theme.spacing(5),
                  background: theme.palette.background.paper,
                  boxShadow: theme.palette.shadows.paperBoxShadow,
                }}
              >
                <Typography variant="h3" gutterBottom color="error.main">
                  Unable to load data. Please refresh the page and try again.
                </Typography>
              </Box>
            ) : (
              combinedWatchlist.length === 0 && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    p: theme.spacing(5),
                    background: theme.palette.background.paper,
                    boxShadow: theme.palette.shadows.paperBoxShadow,
                  }}
                >
                  <Typography variant="h3" gutterBottom color="text.primary">
                    {!hasWatchlistAccess ? (
                      <a
                        onClick={() => {
                          setUpsellOpen(true);
                        }}
                      >
                        Upgrade for access to watchlist symbols
                      </a>
                    ) : combinedWatchlist.length === 0 ? (
                      selectedWatchlistIds.length === 0 ? (
                        'Please select at least one watchlist.'
                      ) : (
                        'No tickers in selected watchlists.'
                      )
                    ) : (
                      ''
                    )}
                  </Typography>
                </Box>
              )
            )}
            {type === ProductType.HIRO && !hasHiroAccess ? undefined : (
              <Loader isLoading={watchlistLoading}>
                <Box
                  sx={{
                    transition: '0.5s',
                    height: '100%',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      overflowX: 'auto',
                    }}
                  >
                    {combinedWatchlist.length > 0 && !fetchingError ? (
                      <CardLayout>{cards}</CardLayout>
                    ) : (
                      <Box sx={{ height: '100%' }} />
                    )}
                  </Box>
                </Box>
              </Loader>
            )}
          </TabPanel>
          {type === ProductType.HIRO && (
            <TabPanel value="alerts" sx={{ maxHeight: 'calc(100% - 50px)' }}>
              {hasHiroAccess ? (
                <AlertsTabBody />
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    p: theme.spacing(5),
                    background: theme.palette.background.paper,
                    boxShadow: theme.palette.shadows.paperBoxShadow,
                  }}
                >
                  <Typography variant="h3" gutterBottom color="text.primary">
                    <a
                      onClick={() => {
                        setUpsellOpen(true);
                      }}
                    >
                      Upgrade for access to alerts
                    </a>
                  </Typography>
                </Box>
              )}
            </TabPanel>
          )}
        </TabContext>
      </Box>
      <UpsellModal
        open={upsellOpen}
        setOpen={setUpsellOpen}
        title={HIRO_UPSELL.title}
        subtitle={
          upgradeToHiro ? HIRO_UPSELL.upgrade_subtitle : HIRO_UPSELL.subtitle
        }
        items={HIRO_UPSELL.items}
      />
      <EditWatchlists
        onClose={() => setShowEditWatchlistModal(false)}
        open={showEditWatchlistModal}
        modal={true}
        // toast needs to be displayed after the modal disappears
        openToast={(toastData) => setTimeout(() => openToast(toastData), 1000)}
      />
    </WatchlistAndTrendingScrollContainer>
  );
};
