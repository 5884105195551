import { Box, Button, IconButton } from '@mui/material';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import {
  futuresSnapshotState,
  futuresVisibleState,
  isMobileState,
  screenWidthState,
  userIsLoggedInState,
} from '../../states';
import { useTheme } from '@mui/material/styles';
import { LastPrice } from '../shared/LastPrice';
import useSetSym from '../../hooks/hiro/useSetSym';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import useUserDetails from '../../hooks/user/useUserDetails';
import { SGTooltip } from '../core';
import { productTypeForPath, READABLE_PROD_TYPES } from '../../util';
import { FuturesSnapshotData } from '../../types';

const CHANGE_INTERVAL = 35_000; // give it time to update before rotating
const SNAPSHOT_WIDTH = 100;
const SNAPSHOT_TOTAL_MARGIN_X = 4;

export const TickersSnapshot = ({}) => {
  const isMobile = useRecoilValue(isMobileState);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [changeTimeout, setChangeTimeout] = useState<
    NodeJS.Timer | undefined
  >();
  const [hovering, setHovering] = useState(false);
  const snapshots = useRecoilValue(futuresSnapshotState);
  const { getSymUrl } = useSetSym();
  const navigate = useNavigate();
  const theme = useTheme();
  const { saveSgSettings } = useUserDetails();
  const containerRef = useRef<HTMLElement | null>(null);
  const [maxAtOnce, setMaxAtOnce] = useState(0);
  const screenWidth = useRecoilValue(screenWidthState);
  const futuresVisible = useRecoilValue(futuresVisibleState);
  const loggedIn = useRecoilValue(userIsLoggedInState);

  useLayoutEffect(() => {
    if ((snapshots?.length ?? 0) === 0) {
      return setMaxAtOnce(0);
    }

    const width = containerRef.current?.scrollWidth;
    const maxCanFit = Math.floor(
      (width ?? 0) / (SNAPSHOT_WIDTH + SNAPSHOT_TOTAL_MARGIN_X),
    );
    // try to have each change be the same number of tickers to avoid it being distracting
    // e.g. if you have 6 snapshots but only 5 fit on the screen at once
    // do 2 rotations of 3 each instead of 5 then 1
    const numChangesReq = Math.ceil(snapshots.length / maxCanFit);
    setMaxAtOnce(Math.ceil(snapshots.length / numChangesReq));
  }, [snapshots, screenWidth]);

  useEffect(() => {
    if (maxAtOnce === 0 || !futuresVisible) {
      return;
    }

    if (maxAtOnce >= snapshots.length) {
      clearInterval(changeTimeout);
      setCurrentIndex(0);
      setChangeTimeout(undefined);
    } else if (changeTimeout == null) {
      setChangeTimeout(
        setTimeout(() => {
          triggerDisplayChange();
        }, CHANGE_INTERVAL),
      );
    }
  }, [snapshots, changeTimeout, futuresVisible, maxAtOnce]);

  const triggerDisplayChange = () => {
    const newIndex = currentIndex + maxAtOnce;
    setCurrentIndex(newIndex >= snapshots.length ? 0 : newIndex);
    setChangeTimeout(undefined);
  };

  const snapshotsToShow = () => {
    const maxCount = maxAtOnce;
    if (maxCount >= snapshots.length) {
      return snapshots;
    }

    const startIndex = currentIndex >= snapshots.length ? 0 : currentIndex;
    return snapshots.slice(startIndex, startIndex + maxCount);
  };

  const renderAllSnapshots = () => {
    const snapshotsShown = snapshotsToShow();
    return (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: isMobile ? 'center' : 'flex-end',
        }}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        ref={containerRef}
      >
        <FuturesHideButton
          visible={hovering}
          onClick={() => saveSgSettings({ tickersHidden: true })}
        />
        {snapshots.map((data) =>
          renderSnapshot(data, snapshotsShown.includes(data)),
        )}
      </Box>
    );
  };

  const renderSnapshot = (data: FuturesSnapshotData, visible: boolean) => {
    const url = data.target == null ? undefined : getSymUrl(data.target);
    const onClick = url == null ? undefined : () => navigate(url);
    const urlProdType = url == null ? null : productTypeForPath(url);
    const prodTypeName = urlProdType ? READABLE_PROD_TYPES[urlProdType] : null;

    return (
      <SGTooltip
        key={data.sym}
        title={
          url == null
            ? `${data.sym} not supported in HIRO or Equity Hub`
            : `Navigate to ${data.target}${
                prodTypeName ? ` in ${prodTypeName}` : ''
              }`
        }
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            borderRadius: visible ? '3px' : 0,
            marginX: visible ? SNAPSHOT_TOTAL_MARGIN_X / 2 : 0,
            transition: 'opacity 0.1s ease',
            width: visible ? SNAPSHOT_WIDTH : 0,
            visibility: visible ? 'visible' : 'hidden',
            opacity: visible ? 1 : 0,
            cursor: url == null ? undefined : 'pointer',
          }}
          key={data.sym}
        >
          <LastPrice
            sym={data.sym}
            lastPrice={data.lastPrice}
            lastClose={data.lastClose}
            onClick={onClick}
          />
        </Box>
      </SGTooltip>
    );
  };

  const renderShowButton = () => {
    return (
      <Button
        variant="outlined"
        sx={{
          textTransform: 'none',
          fontSize: '11px',
          height: '30px',
          whiteSpace: 'nowrap',
          alignSelf: 'center',
          padding: '6px',
        }}
        onClick={() => saveSgSettings({ tickersHidden: false })}
      >
        Show Tickers
      </Button>
    );
  };

  return loggedIn ? (
    <Box
      sx={{
        height: '45px', // so this takes up space even while snapshots are loading
        display: 'flex',
        justifyContent: isMobile ? 'center' : 'flex-end',
        flexGrow: 1,
        width: '100%',
      }}
    >
      {futuresVisible ? renderAllSnapshots() : renderShowButton()}
    </Box>
  ) : null;
};

const FuturesHideButton = ({
  visible,
  onClick,
}: {
  visible: boolean;
  onClick: () => void;
}) => {
  const theme = useTheme();

  return (
    <IconButton
      aria-label="hide futures"
      onClick={onClick}
      sx={{
        position: 'relative',
        right: '-9px',
        top: '-3px',
        padding: 0,
        borderRadius: '16px',
        border: `1px solid ${theme.palette.text.disabled}`,
        display: 'inline-block',
        flexGrow: 0,
        height: visible ? '15px' : 0,
        width: visible ? '15px' : 0,
        visibility: visible ? 'visible' : 'hidden',
        opacity: visible ? 1 : 0,
        transition: 'all 0.2s ease',
        color: theme.palette.text.disabled,
        '&:hover': {
          color: `${theme.palette.text.primary} !important`,
          border: `1px solid ${theme.palette.text.primary}`,
        },
      }}
    >
      <CloseIcon
        sx={{
          fontSize: '9px',
          position: 'relative',
          right: '0px',
          top: '-8px',
        }}
      />
    </IconButton>
  );
};
