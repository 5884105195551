import { ET, getCachedToken } from './shared';
import { Dayjs } from 'dayjs';
import { dayjs } from './shared/date';
import { TokenTimeframe, TRACE_TOKEN_OVERRIDES } from '../config/user';

// find the first matching valid override, or undefined if no matches
export const getTraceOverrideTimeframe = (time: Dayjs) =>
  TRACE_TOKEN_OVERRIDES.find((tf) => isInTraceOverrideTimeframe(time, tf));

export const isInTraceOverrideTimeframe = (
  time: Dayjs,
  traceOverride: TokenTimeframe,
) => {
  return time.isBetween(traceOverride.minDate, traceOverride.maxDate);
};

export const isValidTraceTimeframe = () => {
  const nowET = dayjs().tz(ET);

  return TRACE_TOKEN_OVERRIDES.some((traceOverride) =>
    isInTraceOverrideTimeframe(nowET, traceOverride),
  );
};

export const getTraceOverrideToken = () => {
  const nowET = dayjs().tz(ET);

  const validOverride = TRACE_TOKEN_OVERRIDES.find((traceOverride) =>
    isInTraceOverrideTimeframe(nowET, traceOverride),
  );

  if (validOverride) {
    return validOverride.token;
  }

  return null;
};

export const getUserTraceToken = (hasAlphaAccess = false) => {
  if (hasAlphaAccess) {
    return getCachedToken(); // No need for the special token since user already has alpha access
  }

  // Falls outside the valid timeframe so return the regular user cached token if any
  return getTraceOverrideToken() ?? getCachedToken();
};
