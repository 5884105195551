import { Page } from './shared/Page';
import { Hiro } from './components/Hiro';
import useAuth from '../hooks/auth/useAuth';
import { ProductType } from '../types';
import { useRecoilValue } from 'recoil';
import { userDetailsState } from '../states';
import { PageBanner } from './shared/PageBanner';
import { useState } from 'react';

export const HiroPage = () => {
  const { productsWithAccess } = useAuth();
  const userDetails = useRecoilValue(userDetailsState);
  const showProUpsellBanner =
    !productsWithAccess.has(ProductType.HIRO) && userDetails != null;
  const [isOpen, setIsOpen] = useState(true);

  return (
    <Page>
      {showProUpsellBanner && (
        <PageBanner
          message={
            'Viewing delayed HIRO. Click here to upgrade to Alpha to get full access to HIRO'
          }
          onClick={() =>
            window.open(
              'https://spotgamma.com/subscribe-to-spotgamma/',
              '_blank',
            )
          }
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      )}
      <Hiro />
    </Page>
  );
};
