import { Stack } from '@mui/material';
import { Link } from './Link';
import { useMemo } from 'react';
import {
  expandedLinkGroupIdState,
  isMobileState,
  isSideDrawerExpandedState,
  productAccessState,
  userDashAccessState,
  userSubLevelState,
} from '../../states';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  HOME_LINK,
  INTERNAL_ONLY_PRODUCTS,
  NON_PROD,
  PRODUCT_LINKS,
  SUBSCRIPTION_PRODUCTS,
} from '../../config';
import {
  DashAccess,
  hasDashAccess,
  hasDashAccessToProduct,
  ProductType,
  SubLevel,
} from '../../types';
import useSetSym from 'hooks/hiro/useSetSym';
import GroupHeader from './sidebar/GroupHeader';
import { isValidTraceTimeframe } from 'util/user';

const createGroup = (
  title: string,
  subLevelRequired: SubLevel,
  access: DashAccess | null = null,
  products: ProductType[] | null = null,
) => ({
  title,
  subLevelRequired,
  access,
  products,
});

const PRODUCT_GROUPS = [
  createGroup('Market Central', SubLevel.STANDARD),
  createGroup('Equity Console', SubLevel.PRO),
  createGroup('Real Time Signals', SubLevel.ALPHA),
  // Internal group has SubLevel.NONE solely for sorting purposes
  createGroup(
    'Internal',
    SubLevel.NONE,
    DashAccess.INTERNAL,
    INTERNAL_ONLY_PRODUCTS,
  ),
];

export const Products = () => {
  const isMobile = useRecoilValue(isMobileState);
  const { getSymRaw } = useSetSym();
  const [isDrawerExpanded, setIsDrawerExpanded] = useRecoilState(
    isSideDrawerExpandedState,
  );

  const products = useRecoilValue(productAccessState);
  const productsWithAccess = useMemo(() => new Set(products), [products]);
  const userLevel = useRecoilValue(userSubLevelState);
  const userDashAccess = useRecoilValue(userDashAccessState);
  const setExpandedLinkGroupId = useSetRecoilState(expandedLinkGroupIdState);

  const closeSidebar = () => {
    setIsDrawerExpanded(false);
    setExpandedLinkGroupId(undefined);
  };

  const onLinkClick = () => {
    if (isMobile) {
      closeSidebar();
    }
  };

  const getMergedLink = (link: string) => {
    let finalUrl = link;
    const symParam = getSymRaw();
    if (symParam) {
      const searchParams = new URLSearchParams();
      searchParams.set('sym', symParam);
      finalUrl = `${finalUrl}?${searchParams.toString()}`;
    }

    return finalUrl;
  };

  return (
    <Stack gap={7}>
      <Link
        isLabelShown={isDrawerExpanded}
        key={HOME_LINK.label}
        to={getMergedLink(HOME_LINK.link)}
        icon={HOME_LINK.icon}
        text={HOME_LINK.label}
        isNew={false}
        isLocked={!productsWithAccess.has(ProductType.HOME)}
        onClick={onLinkClick}
      />
      {PRODUCT_GROUPS.sort((pg1, pg2) => {
        const pg1Level = pg1.subLevelRequired;
        const pg2Level = pg2.subLevelRequired;
        // If 'a' or 'b' is the user's level, it gets highest priority
        if (pg1Level === userLevel) {
          return -1;
        }
        if (pg2Level === userLevel) {
          return 1;
        }

        // If both 'a' and 'b' are greater than userLevel, they should be sorted in ascending order
        if (pg1Level > userLevel && pg2Level > userLevel) {
          return pg1Level - pg2Level;
        }

        // If 'a' is greater than the userLevel and 'b' is not, 'a' should come after 'b'
        if (pg1Level > userLevel) {
          return 1;
        }
        if (pg2Level > userLevel) {
          return -1;
        }

        // For any levels below the user's level, sort in descending order
        return pg2Level - pg1Level;
      }).map(({ title, subLevelRequired, access, products }) => {
        if (products == null) {
          products = SUBSCRIPTION_PRODUCTS[subLevelRequired];
        }
        // make sure we only show products the user has dash access to
        products = products?.filter((product) =>
          hasDashAccessToProduct(userDashAccess, product),
        );

        if (
          (access != null && !hasDashAccess(userDashAccess, access)) ||
          (products?.length ?? 0) === 0
        ) {
          return null;
        }

        return (
          <Stack key={title} gap={2}>
            <GroupHeader title={title} subLevelRequired={subLevelRequired} />
            <Stack gap={1}>
              {products.map((product: ProductType) => {
                const { link, label, icon, isNew, betaOnly } =
                  PRODUCT_LINKS[product];
                const finalUrl = getMergedLink(link);

                return (
                  (!betaOnly || NON_PROD) && (
                    <Link
                      isLabelShown={isDrawerExpanded}
                      key={label}
                      to={finalUrl}
                      icon={icon}
                      text={label}
                      isNew={isNew || false}
                      isLocked={!productsWithAccess.has(product)}
                      isSpecialUnlocked={
                        product === ProductType.TRACE &&
                        isValidTraceTimeframe() &&
                        userLevel !== SubLevel.ALPHA
                      }
                      onClick={onLinkClick}
                    />
                  )
                );
              })}
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
};
