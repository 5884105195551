import {
  IntradayGammaLense,
  IntradayStrikeBarType,
  SubLevel,
  TraceGreek,
} from '../../types';
import { useEffect, useState } from 'react';
import poll from '../../util/poll';
import { getAuthHeader, isPollingOpen } from '../../util';
import { getUserTraceToken } from '../../util/user';
import { useRecoilValue, useRecoilState } from 'recoil';
import {
  oiLatestServerTsMapState,
  oiStrikeBarTypeState,
  userSubLevelState,
  workerState,
} from '../../states';
import { IntradayFiltersToParamKeys } from '../../config';
import { useLog } from '../../hooks';
import dayjs from 'dayjs';

type usePollingProps = {
  latestContourTs: number | undefined;
  latestStrikeBarTs: number | undefined;
  triggerContourUpdate: () => Promise<void>;
  triggerStrikeBarsUpdate: () => Promise<void>;
  selectedGreek: TraceGreek;
  intradayDate: dayjs.Dayjs;
};

const POLL_INTERVAL = 10_000;

export const usePolling = ({
  latestContourTs,
  latestStrikeBarTs,
  triggerContourUpdate,
  triggerStrikeBarsUpdate,
  selectedGreek,
  intradayDate,
}: usePollingProps) => {
  const worker = useRecoilValue(workerState);
  const userLevel = useRecoilValue(userSubLevelState);
  const strikeBarType = useRecoilValue(oiStrikeBarTypeState);

  const { logError, nonProdDebugLog } = useLog('usePolling');

  const [latestServerTsMap, setLatestServerTsMap] = useRecoilState(
    oiLatestServerTsMapState,
  );
  const [lastUpdateCheckAt, setLastUpdateCheckAt] = useState(dayjs());
  // getCurrentDate returns in ET

  const getLatestServerTs = (
    type: IntradayGammaLense | IntradayStrikeBarType,
  ) => {
    const key = IntradayFiltersToParamKeys.get(type);
    if (key == null) {
      return undefined;
    }

    return latestServerTsMap.get(key);
  };

  const checkForHeatmapUpdate = async () => {
    setLastUpdateCheckAt(dayjs());

    const selectedGreekLatestServerTs =
      selectedGreek === TraceGreek.Gamma
        ? getLatestServerTs(IntradayGammaLense.GAMMA)
        : getLatestServerTs(IntradayGammaLense.DELTA);

    nonProdDebugLog(
      `polling: Last server update for contour ${selectedGreek}: `,
      dayjs(selectedGreekLatestServerTs),
    );
    nonProdDebugLog(
      `polling: Current latest timestamp for contour ${selectedGreek}: `,
      dayjs(latestContourTs),
    );

    if (
      selectedGreekLatestServerTs != null &&
      (latestContourTs ?? Infinity) < selectedGreekLatestServerTs
    ) {
      await triggerContourUpdate();
    }
  };

  const checkForStrikeBarsUpdate = () => {
    const latestStrikeBarServerTs = getLatestServerTs(strikeBarType);
    nonProdDebugLog(
      `polling: Last server update for strike bar ${strikeBarType}: `,
      dayjs(latestStrikeBarServerTs),
    );
    nonProdDebugLog(
      `polling: Current latest timestamp for strike bar ${strikeBarType}: `,
      dayjs(latestStrikeBarTs),
    );

    if (
      latestStrikeBarServerTs != null &&
      (latestStrikeBarTs ?? Infinity) < latestStrikeBarServerTs
    ) {
      triggerStrikeBarsUpdate();
    }
  };

  useEffect(() => {
    if (!isPollingOpen()) {
      return;
    }

    checkForHeatmapUpdate();
  }, [latestServerTsMap, latestContourTs, intradayDate, selectedGreek]);

  useEffect(() => {
    // separate useeffect so that changing strike bar type does not then update the 'last update checked at' state
    // in checkForHeatmapUpdate(). we only want that updated once we actually have checked for a new update
    if (!isPollingOpen()) {
      return;
    }

    checkForStrikeBarsUpdate();
  }, [latestServerTsMap, latestStrikeBarTs, intradayDate, strikeBarType]);

  const handleLatestResponse = ({ json }: any) => {
    try {
      setLatestServerTsMap(new Map(Object.entries(json)));
    } catch (err) {
      logError(err, 'handleLatestResponse');
    }
  };

  useEffect(() => {
    // fetch latest timestamps for everything
    // otherwise if user changes the strike bar type we will have an issue where we reset the poller
    // theoretically that can result in us never polling if the user keeps switching the strike bar types
    const types = [
      IntradayStrikeBarType.GAMMA,
      IntradayStrikeBarType.OI,
      IntradayGammaLense.DELTA,
      IntradayGammaLense.GAMMA,
    ].map((item) => [IntradayFiltersToParamKeys.get(item)!]);

    return poll(
      worker,
      {
        url: `v1/oi/intradayLatestTime?types=${encodeURIComponent(
          types.join(','),
        )}`,
        interval: POLL_INTERVAL,
        onResponse: handleLatestResponse,
        noPollOnInit: true,
      },
      {
        ...getAuthHeader(getUserTraceToken(userLevel === SubLevel.ALPHA)),
      },
    );
  }, [worker, userLevel]);

  return { lastUpdateCheckAt: isPollingOpen() ? lastUpdateCheckAt : null };
};
