import { ResourceType } from 'types/resources';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import {
  IVolTab,
  IndicesContentType,
  SentimentTab,
  SubLevel,
  IntradayGammaLense,
} from 'types';
import PeopleIcon from '@mui/icons-material/People';
import { isBloomberg } from '../util/bloomberg'; // need /bloomberg here to avoid recursive deps

const academy = {
  type: ResourceType.EDUCATION,
  name: 'Academy',
  link: 'https://academy.spotgamma.com/?_gl=1*1hrx7ke*_ga*OTcxNjk1NjM5LjE2NjE3ODU0NzE.*_ga_SMJRNHH18H*MTY4NDM2NjMwNy4xMDguMC4xNjg0MzY2MzA3LjAuMC4w',
  description:
    'Trustworthy options education with 6+ hours of premium options education.',
};

const syllabus = {
  type: ResourceType.SUPPORT,
  name: 'Syllabus',
  link: 'https://spotgamma.com/syllabus/',
  description:
    'All the videos and resources from onboarding to maximize your account.',
};

const webinarReplays = {
  type: ResourceType.COMMUNITY,
  name: 'Webinar Replays',
  link: 'https://spotgamma.com/webinar-replays/',
  description:
    'Subscriber-only webinars and exclusive trainings from our team of experts.',
};

const supportCenter = {
  type: ResourceType.SUPPORT,
  name: 'Support Center',
  link: 'https://support.spotgamma.com/?_gl=1*icnfo3*_ga*OTcxNjk1NjM5LjE2NjE3ODU0NzE.*_ga_SMJRNHH18H*MTY4NDM2NjMwNy4xMDguMC4xNjg0MzY2MzA3LjAuMC4w',
  description:
    'Supplemental terminology, definitions, and use cases for proprietary terms and trading concepts.',
};

export const MAIN_RESOURCES = [
  academy,
  syllabus,
  webinarReplays,
  supportCenter,
];

export const RESOURCE_LIST = [
  {
    type: ResourceType.EDUCATION,
    resources: [
      {
        name: 'Blog',
        link: 'https://spotgamma.com/blog/',
        description:
          "Gamma news, options market commentary, and timely articles on what's happening in the markets.",
      },
      academy,
      {
        name: 'Free Courses',
        link: 'https://academy.spotgamma.com/course/30-30?_gl=1*1hrx7ke*_ga*OTcxNjk1NjM5LjE2NjE3ODU0NzE.*_ga_SMJRNHH18H*MTY4NDM2NjMwNy4xMDguMC4xNjg0MzY2MzA3LjAuMC4w',
        description:
          'Introductory courses on why options matter with powerful examples of how options drive stocks.',
      },
    ],
  },
  {
    type: ResourceType.COMMUNITY,
    resources: [
      {
        type: ResourceType.COMMUNITY,
        name: 'Discord',
        link: 'resources/discord',
        description:
          'Join the private SpotGamma Discord server, home to a lively and generous community of traders.',
      },
      webinarReplays,
      {
        type: ResourceType.COMMUNITY,
        name: 'Recent Media',
        link: 'https://spotgamma.com/media/#op3-element-6OhpbuG4',
        description:
          'Trusted industry experts, check out the latest articles and media featuring SpotGamma commentary.',
      },
      {
        type: ResourceType.COMMUNITY,
        name: 'Affiliates',
        link: 'https://spotgamma.com/affiliate-dashboard/',
        description:
          'Become a SpotGamma affiliate and get paid to share our analysis with others!',
      },
    ],
  },
  {
    type: ResourceType.SUPPORT,
    resources: [supportCenter, syllabus],
  },
];

export const DISCORD_CONTENT_LIST = [
  {
    icon: <PeopleIcon />,
    name: 'Join the Community',
    description: `Engage with other likeminded traders using SpotGamma's tools to analyze the market. Our community features hundreds of active users ready to share insights, discuss their latest trades, or just chat about the market.`,
  },
  {
    icon: <LightbulbIcon />,
    name: 'Share Trade Ideas',
    description: `Drop your latest trades, or take a peak at what stocks other users are looking at. This private Discord includes SpotGamma content contributors providing their commentary and market insights each week.`,
  },
  {
    icon: <AutoGraphIcon />,
    name: 'Leverage Our Bots',
    description: `Our users can access SpotGamma charts on over 3,500 US stocks to aid themselves and others in their trading research. Discord community members can also tap into third-party tools including charts from TradingView, data look ups, orderbook visualizations, and 24-hour volume.`,
  },
];

export const SUBLEVEL_DISCORD_MEMBERSHIP_LEVEL_MAPPING = {
  [SubLevel.ALPHA]: 'pro',
  [SubLevel.PRO]: 'pro',
  [SubLevel.STANDARD]: 'standard',
  [SubLevel.NONE]: 'none',
};

export const ZENDESK_ARTICLE_ID_MAPPING: { [key: string]: string } =
  isBloomberg()
    ? {
        'HIRO-watchlist': '20573607040915',
        'HIRO-trending': '18660673979667',
        'hiro-info': '18660578974739',
      }
    : {
        composite: '14356919886227',
        pc_impact: '8711555931027',
        live_price: '14356952534291',
        skew: '8711432245779',
        history: '14355937614099',
        rr: '15412858897043',
        [IVolTab.FixedStrikeMatrix]: '23982224020115',
        [IVolTab.TermStructure]: '23982205386131',
        [IVolTab.VolSkew]: '23982082962195',
        'eh-levels-info': '14356859960339',
        scanners: '14356579936403',
        'hiro-info': '4420646443539',
        [IndicesContentType.REAL_TIME_LEVELS]: '15350634159123',
        [IndicesContentType.GAMMA_LEVELS]: '15350709067027',
        [IndicesContentType.TILT]: '15350737304595',
        [IndicesContentType.EXP_CONCENTRATION]: '15350774629523',
        [IndicesContentType.COMBO_STRIKES]: '15350781318291',
        [IndicesContentType.ZERO_DTE]: '15350782964755',
        [IndicesContentType.GAMMA_MODEL]: '15350835468307',
        [IndicesContentType.VANNA_MODEL]: '15350867797267',
        [IndicesContentType.DELTA_MODEL]: '15350839753875',
        [IndicesContentType.VOLFORECAST_MODEL]: '15350841762963',
        [IndicesContentType.OI_VOLUME]: '15350877023123',
        [IndicesContentType.EXP_CONCENTRATION_TABLE]: '15350936322963',
        [IndicesContentType.STRIKE_CONCENTRATION_TABLE]: '15350951180179',
        [IndicesContentType.OPTIONS_RISK_REVERSAL_CHART]: '15350940293523',
        [IndicesContentType.HISTORICAL_CHART]: '15350990363795',
        [IndicesContentType.MAX_REAL_VOL]: '15350875021075', // 2-month / 6-month
        [IndicesContentType.HIST_RETURNS]: '15350947392915', // Realized Volatility
        [IndicesContentType.VOLATILITY]: '15350881328403', // 5/30-day histogram

        'founders-note-info': '15341610402579',
        [SentimentTab.OCC]: '1500006839861',
        [SentimentTab.ZeroDTE]: '15417083322899',
        [SentimentTab.EquityPutCallRatio]: '15417104162835',
        [SentimentTab.ExpirationConcentration]: '15417155086355',

        'HIRO-trending': '4421139806483',
        'HIRO-watchlist': '4421171646355',
        'Equity Hub-trending': '4421139806483',
        'Equity Hub-watchlist': '14356000748819',
        [`TRACE-${IntradayGammaLense[IntradayGammaLense.GAMMA]}`]:
          '33608037264787',
        [`TRACE-${
          IntradayGammaLense[IntradayGammaLense.DELTA_CHARM_DIRECTIONAL]
        }`]: '33608198289043',
        [`TRACE-${IntradayGammaLense[IntradayGammaLense.DELTA_DIRECTIONAL]}`]:
          '33608084842643',
        [`TRACE-STRIKE-PLOT`]: '33608227551379',
      };
