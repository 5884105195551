import { Box, Stack } from '@mui/material';
import OptionsFeedDatagrid from './OptionsFeedDatagrid';
import useTnSWebsocket from 'hooks/optionsFeed/useTnSWebsocket';
import { useEffect, useMemo, useState } from 'react';
import {
  RecoilState,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import { hiroSymbolsState } from 'states';
import { decode } from '@msgpack/msgpack';
import {
  DEFAULT_TNS_SYMBOLS,
  OF_DEFAULT_FILTER_ID,
  OptionsFeedDataGridTab,
  OptionsFeedFilterTab,
} from 'config/optionsFeed';
import { STREAM_HOST_URL } from 'config/shared';
import {
  getFiltersForPayload,
  getOptionFeedDataRow,
  getSortedOptionsFeedData,
} from 'util/optionsFeed';
import { fetchRawAPI } from 'util/shared';
import { useLog } from 'hooks';
import {
  Filter,
  FilterConfig,
  FilterItem,
  FilterPanelProps,
  OptionsFeedColumnKey,
  OptionsFeedColumnSizes,
  RawOptionFeedData,
} from 'types/optionsFeed';
import {
  GridColumnVisibilityModel,
  GridSlotsComponentsProps,
  GridSortModel,
} from '@spotgamma/x-data-grid-premium';
import { useOptionsFeedColumns } from './useOptionsFeedColumns';
import FiltersContainer from './filters/FiltersContainer';
import useEquities from 'hooks/equityhub/useEquities';
import {
  tnsDataGridActiveTabState,
  tnsEquityScannersDataState,
} from 'states/optionsFeed';
import useTnsFilters from 'hooks/optionsFeed/useTnsFilters';
import useToast from 'hooks/useToast';
import { TabContext, TabPanel } from '@mui/lab';
import { Tabs } from 'components/shared';
import DataGridFlowSummary from './summary/DataGridFlowSummary';
import useHomeContent from 'hooks/home/useHomeContent';
import { Earnings } from 'types';

interface OptionsFeedProps {
  filterPanelProps: FilterPanelProps;
  disabledColumnFilters?: OptionsFeedColumnKey[];
  disableWatchlistSelector?: boolean;
  tnsFlowLiveState: RecoilState<boolean>;
  activeCustomFilterState: RecoilState<FilterConfig | undefined>;
  newFilterItemsState: RecoilState<Filter[]>;
  columnSortModel: RecoilState<GridSortModel>;
  savedFiltersState: RecoilState<FilterConfig[]>;
  filterActiveTabState: RecoilState<OptionsFeedFilterTab>;
  columnVisibilityState: RecoilState<GridColumnVisibilityModel>;
  columnOrderState: RecoilState<OptionsFeedColumnKey[]>;
  columnSizingState: RecoilState<OptionsFeedColumnSizes>;
  activeWatchlistIdsState: RecoilState<number[]>;
  customGridSlotProps?: GridSlotsComponentsProps;
  isHiroView?: boolean;
}

const OptionsFeed = ({
  disabledColumnFilters,
  filterPanelProps,
  activeCustomFilterState,
  savedFiltersState,
  newFilterItemsState,
  columnVisibilityState,
  columnOrderState,
  columnSortModel,
  columnSizingState,
  filterActiveTabState,
  tnsFlowLiveState,
  customGridSlotProps,
  isHiroView = false,
}: OptionsFeedProps) => {
  const { logError } = useLog('OptionsFeedPage');
  const { openToast } = useToast();
  const hiroSyms = useRecoilValue(hiroSymbolsState);
  const isTnsFlowLive = useRecoilValue(tnsFlowLiveState);
  const [activeGridTab, setActiveGridTab] = useRecoilState(
    tnsDataGridActiveTabState,
  );
  const sortModel = useRecoilValue(columnSortModel);
  const [eqScannersLoading, setEqScannersLoading] = useState<boolean>(false);
  const setEqScanners = useSetRecoilState(tnsEquityScannersDataState);
  const { getEquityScanners } = useEquities();
  const [histData, setHistData] = useState<RawOptionFeedData[]>([]);
  const [histError, setHistError] = useState<string | null>(null);
  const [histDataLoading, setHistDataLoading] = useState<boolean>(false);

  const { getEarningsForSyms } = useHomeContent();

  const [earningsList, setEarningsList] = useState<Earnings[]>([]);

  const [earningsLoading, setEarningsLoading] = useState<boolean>(false);

  const activeCustomFilter = useRecoilValue(activeCustomFilterState);
  const newFilters = useRecoilValue(newFilterItemsState);

  const currentFilters = activeCustomFilter?.value ?? newFilters;

  const setSavedFilters = useSetRecoilState(savedFiltersState);

  const { fetchSavedFilters } = useTnsFilters();

  const { columns } = useOptionsFeedColumns({
    disabledColumnFilters,
    earningsList,
  });

  const currentSyms = useMemo(() => {
    if (filterPanelProps.currentSym) {
      return [filterPanelProps.currentSym];
    }

    return (
      ((
        currentFilters.find(
          (f) => f.id === OF_DEFAULT_FILTER_ID.Symbols,
        ) as FilterItem
      )?.value as string[]) ?? DEFAULT_TNS_SYMBOLS
    );
  }, [currentFilters, filterPanelProps.currentSym]);

  // data comes through this websocket
  const { error, rows } = useTnSWebsocket(
    currentSyms,
    currentFilters,
    isTnsFlowLive,
  );

  useEffect(() => {
    async function fetchFilters() {
      try {
        const myFilters: FilterConfig[] = await fetchSavedFilters(false); // fetch only noSym:false filters
        setSavedFilters(myFilters);
      } catch (err: any) {
        console.error(err);
        openToast({
          message: err.message,
          type: 'error',
          duration: 10000,
        });
      }
    }
    fetchFilters();
  }, []);

  useEffect(() => {
    async function fetchEquityScanners() {
      try {
        setEqScannersLoading(true);
        const sc = await getEquityScanners(); // Fetch all equity scanners data
        setEqScanners(sc);
      } catch (err) {
        console.error(err);
        openToast({
          message:
            'Something went wrong while fetching equity scanners data. Refresh the page to retry or contact us if the issue persists.',
          type: 'error',
          duration: 10000,
        });
      } finally {
        setEqScannersLoading(false);
      }
    }
    fetchEquityScanners();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setEarningsLoading(true);

      try {
        const earnings = await getEarningsForSyms([...hiroSyms]);
        setEarningsList(earnings);
      } catch (err) {
        logError(err, 'fetch earnings list data');
      } finally {
        setEarningsLoading(false);
      }
    };

    fetchData();
  }, [hiroSyms]);

  const getTnsFeed = async () => {
    try {
      setHistError(null);
      setHistDataLoading(true);

      // todo: figure out pagination with infinite scroll
      const response = await fetchRawAPI(
        `sg/tns_feed?filters=${encodeURIComponent(
          JSON.stringify(getFiltersForPayload(currentFilters)),
        )}&sorting=${encodeURIComponent(JSON.stringify(sortModel))}`,
        undefined,
        STREAM_HOST_URL,
      );

      const arrayBuffer = await response.arrayBuffer();
      const histData: any = decode(arrayBuffer);

      const cleanData = [
        ...histData.map((d: any[]) => getOptionFeedDataRow(d)),
      ];

      setHistData(cleanData);
    } catch (err) {
      logError(err);
      setHistError('Something went wrong while fetching data...');
    } finally {
      setHistDataLoading(false);
    }
  };

  useEffect(() => {
    getTnsFeed();
  }, [currentFilters, sortModel]);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        overflowY: 'hidden',
        display: 'flex',
        gap: '8px',
      }}
    >
      <FiltersContainer
        activeCustomFilterState={activeCustomFilterState}
        activeTabState={filterActiveTabState}
        newFilterItemsState={newFilterItemsState}
        savedFiltersState={savedFiltersState}
        filterPanelProps={filterPanelProps}
      />

      <Stack
        sx={{
          backgroundColor: 'background.paper',
          paddingTop: '6px',
          paddingX: '16px',
          paddingBottom: '24px',
          gap: '12px',
          borderRadius: '8px',
          display: 'flex',
          overflow: 'hidden',
          flexGrow: 1,
          maxWidth: '100%',
        }}
      >
        {isHiroView ? (
          <OptionsFeedDatagrid
            rows={getSortedOptionsFeedData([...rows, ...histData], sortModel)}
            columns={columns}
            filterPanelOpenState={filterPanelProps.openState}
            flowLiveState={tnsFlowLiveState}
            columnSortModelState={columnSortModel}
            columnVisibilityState={columnVisibilityState}
            columnOrderState={columnOrderState}
            columnSizingState={columnSizingState}
            customGridSlotProps={customGridSlotProps}
            isError={error != null || histError != null}
            isLoading={earningsLoading || histDataLoading || eqScannersLoading}
          />
        ) : (
          <TabContext value={activeGridTab}>
            <Tabs
              options={
                new Map(
                  Object.values(OptionsFeedDataGridTab).map((t) => [t, t]),
                )
              }
              onChange={(_evt, newTab: OptionsFeedDataGridTab) =>
                setActiveGridTab(newTab)
              }
              isFullWidth
              tabButtonSx={{ minHeight: 40 }}
              tabListSx={{ minHeight: 40 }}
            />
            <TabPanel
              value={OptionsFeedDataGridTab.FlowData}
              sx={{
                padding: 0,
                overflow: 'hidden',
              }}
            >
              <TabPanelWrapper>
                <DataGridFlowSummary filters={currentFilters} />
                <OptionsFeedDatagrid
                  rows={getSortedOptionsFeedData(
                    [...rows, ...histData],
                    sortModel,
                  )}
                  columns={columns}
                  columnSortModelState={columnSortModel}
                  filterPanelOpenState={filterPanelProps.openState}
                  flowLiveState={tnsFlowLiveState}
                  columnVisibilityState={columnVisibilityState}
                  columnOrderState={columnOrderState}
                  columnSizingState={columnSizingState}
                  customGridSlotProps={customGridSlotProps}
                  isError={error != null || histError != null}
                  isLoading={
                    earningsLoading || histDataLoading || eqScannersLoading
                  }
                />
              </TabPanelWrapper>
            </TabPanel>
            <TabPanel
              value={OptionsFeedDataGridTab.ContractData}
              sx={{
                padding: 0,
                overflow: 'hidden',
              }}
            >
              <TabPanelWrapper>
                <DataGridFlowSummary filters={currentFilters} />
                <OptionsFeedDatagrid
                  rows={getSortedOptionsFeedData(
                    [...rows, ...histData],
                    sortModel,
                  )}
                  columns={columns}
                  columnSortModelState={columnSortModel}
                  filterPanelOpenState={filterPanelProps.openState}
                  flowLiveState={tnsFlowLiveState}
                  columnVisibilityState={columnVisibilityState}
                  columnOrderState={columnOrderState}
                  columnSizingState={columnSizingState}
                  customGridSlotProps={customGridSlotProps}
                  isError={error != null || histError != null}
                  isLoading={
                    earningsLoading || histDataLoading || eqScannersLoading
                  }
                />
              </TabPanelWrapper>
            </TabPanel>
          </TabContext>
        )}
      </Stack>
    </Box>
  );
};

const TabPanelWrapper = ({ children }: { children: React.ReactNode }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: 4,
      overflow: 'hidden',
      height: '100%',
      maxHeight: '100%',
      width: '100%',
    }}
  >
    {children}
  </Box>
);

export default OptionsFeed;
