import { useCallback, useEffect } from 'react';
import {
  getCachedToken,
  getEnv,
  logAppcuesIdentify,
  productTypeForPath,
  READABLE_PROD_TYPES,
} from './util';
import ReactGA from 'react-ga4';
import { ProductType } from './types';
import { LOG_FLUSH_INTERVAL_MS, useSetSym } from './hooks';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  currentProductTypeState,
  isMobileState,
  screenHeightState,
  screenWidthState,
  userDetailsState,
  windowIsVisibleState,
} from './states';
import { useMarketOpenCloseListener } from './hooks/useMarketOpenCloseListener';
import { MOBILE_MAX_WIDTH, PRODUCT_LINKS } from './config';
import useLog from './hooks/useLog';
import { useCookies } from 'react-cookie';
import { Location } from 'react-router-dom';

type AppMetadataProps = {
  localToken: string | null | undefined;
  location: Location;
};

export const AppMetadata = ({ localToken, location }: AppMetadataProps) => {
  // we are intentionally making this a component instead of a hook to avoid the
  // main App.tsx component re-rendering whenever state/useEffects here change

  const [currentProductType, setCurrentProductType] = useRecoilState(
    currentProductTypeState,
  );
  const userDetails = useRecoilValue(userDetailsState);
  const setIsVisible = useSetRecoilState(windowIsVisibleState);
  const setIsMobile = useSetRecoilState(isMobileState);
  const setScreenWidth = useSetRecoilState(screenWidthState);
  const setScreenHeight = useSetRecoilState(screenHeightState);

  const { sym } = useSetSym();
  const { logtail } = useLog('AppMetadata');
  const [cookies, setCookie] = useCookies(['disableRedirect', 'sgToken']);
  useMarketOpenCloseListener();

  const handleWindowSizeChange = useCallback(() => {
    const isMobile = window.innerWidth <= MOBILE_MAX_WIDTH;
    setIsMobile(isMobile);
    ReactGA.set({ mobile: isMobile });
    setScreenHeight(window.innerHeight);
    setScreenWidth(window.innerWidth);
  }, [setScreenHeight, setScreenWidth, setIsMobile]);

  handleWindowSizeChange();

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, [handleWindowSizeChange]);

  useEffect(() => {
    const toggleVisible = () => {
      setIsVisible(!document.hidden);
    };

    document.addEventListener('visibilitychange', toggleVisible);

    return () => {
      document.removeEventListener('visibilitychange', toggleVisible);
    };
  }, [setIsVisible]);

  useEffect(() => {
    const flush = () => {
      logtail?.flush();
    };
    const flushInterval = setInterval(flush, LOG_FLUSH_INTERVAL_MS);
    window.addEventListener('beforeunload', flush);

    return () => {
      clearInterval(flushInterval);
      window.removeEventListener('beforeunload', flush);
    };
  }, []);

  useEffect(() => {
    // note that this useEffect will be triggered on preferences saves as well
    logAppcuesIdentify(userDetails);
  }, [userDetails]);

  useEffect(() => {
    setCurrentProductType(productTypeForPath(location.pathname));
  }, [location]);

  useEffect(() => {
    const userId =
      localToken == null ? 'rubber_chicken' : userDetails?.id ?? 'id';
    ReactGA.set({ userId });
  }, [localToken, userDetails]);

  useEffect(() => {
    const readableStr =
      currentProductType == null
        ? null
        : READABLE_PROD_TYPES[currentProductType];
    if (readableStr == null) {
      document.title = 'SpotGamma';
      return;
    }

    const symStr = [
      ProductType.HIRO,
      ProductType.EQUITYHUB,
      ProductType.IMPLIED_VOL,
      ProductType.INDICES,
    ].includes(currentProductType!)
      ? `${sym} `
      : '';

    document.title = `${symStr}${readableStr} - SpotGamma`;
  }, [currentProductType, sym]);

  useEffect(() => {
    const token = getCachedToken();
    // If beta user logs into prod with valid token, redirect them to staging while setting the disableRedirect
    if (
      getEnv() !== 'development' &&
      !window.location.host.includes('localhost') &&
      token &&
      window.location.host !== process.env.REACT_APP_STAGING_HOST &&
      userDetails?.wordpress?.profile?.mepr_modern_dashboard_access ===
        'beta' &&
      !cookies.disableRedirect
    ) {
      setCookie('sgToken', token, {
        expires: new Date(Date.now() + 24 * 60 * 60 * 1000), // 24 hours
        path: '/',
        domain: '.spotgamma.com',
        secure: true,
        sameSite: 'none', // necessary if cookies need to be sent in cross-site requests
      });
      window.location.href = `https://${process.env.REACT_APP_STAGING_HOST}${
        location?.state?.postLoginPath ?? PRODUCT_LINKS[ProductType.HOME].link
      }`;
    }
  }, [userDetails]);

  return <></>;
};
