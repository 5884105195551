import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  futuresSnapshotState,
  futuresVisibleState,
  oiLatestServerTsMapState,
  unseenAlertCountState,
  userIsLoggedInState,
  userSettingsState,
  userSubLevelState,
  workerState,
} from '../states';
import { useCallback, useEffect } from 'react';
import { SubLevel, FuturesSnapshotData, SGSettings } from '../types';
import poll from '../util/poll';
import useLog from './useLog';
import { userNotificationsState } from '../states/notifications';
import { getUserNotification } from '../util/notifications';
import { DEFAULT_NOTIFICATION_DAYS } from '../config/notifications';
import useAlerts from './alerts/useAlerts';

const POLL_UPDATE_INTERVAL = 10_000; // 10 secs

export const usePollUpdate = () => {
  const setAlertCount = useSetRecoilState(unseenAlertCountState);
  const userLevel = useRecoilValue(userSubLevelState);
  const worker = useRecoilValue(workerState);
  const setSnapshots = useSetRecoilState(futuresSnapshotState);
  const setLatestServerTsMap = useSetRecoilState(oiLatestServerTsMapState);
  const futuresVisible = useRecoilValue(futuresVisibleState);
  const loggedIn = useRecoilValue(userIsLoggedInState);
  const setNotifications = useSetRecoilState(userNotificationsState);
  const setSettings = useSetRecoilState(userSettingsState);

  const { fetchAndSetLatestAlerts } = useAlerts();

  const { logError } = useLog('usePollUpdate');

  const handleFuturesSnapshot = (data: any) => {
    if (!Array.isArray(data) || data.length === 0) {
      return;
    }

    setSnapshots((snapshots) => {
      return data.map((d) => {
        if (d.lastPrice != null) {
          return d;
        }
        // if we did not get a lastPrice from the server, use the old price
        const oldSnapshot = snapshots.find((s) => d.sym === s.sym);
        return { ...d, lastPrice: oldSnapshot?.lastPrice };
      }) as FuturesSnapshotData[];
    });
  };

  const handlePollResponse = useCallback(
    (data: { json: any }) => {
      try {
        if (data?.json == null) {
          return;
        }

        const alertCount = data.json.unreadAlertCount?.count;
        if (alertCount != null) {
          const newCount = parseInt(alertCount);
          setAlertCount((oldCount) => {
            if (oldCount !== newCount) {
              fetchAndSetLatestAlerts();
            }

            return newCount;
          });
        }

        const futuresSnapshot = data.json.futuresSnapshot;
        if (futuresSnapshot != null) {
          handleFuturesSnapshot(futuresSnapshot);
        }

        const userNotifications = data.json.userNotifications;
        if (userNotifications != null) {
          setNotifications(userNotifications.map(getUserNotification));
        }

        const settings = data.json.settings as Partial<SGSettings> | undefined;
        if (settings?.lastUpdatedAt != null) {
          setSettings((oldSettings) => {
            const oldLastUpdate = oldSettings?.lastUpdatedAt ?? 0;
            if (oldLastUpdate < settings.lastUpdatedAt!) {
              // do not persist hiro or trace chart settings
              settings.hiro ||= {};
              settings.hiro.chartSettings = oldSettings.hiro?.chartSettings;
              settings.oi = oldSettings.oi;
              return settings;
            }

            return oldSettings;
          });
        }
      } catch (err) {
        logError(err, 'handlePollResponse');
      }
    },
    [setAlertCount, setLatestServerTsMap, setSnapshots, setSettings],
  );

  useEffect(() => {
    // if not logged in, the endpoint will return a 403
    if (!loggedIn) {
      return;
    }

    const hasAlertsAccess = userLevel >= SubLevel.PRO;

    const features: any = {
      user_notifications: {
        days: DEFAULT_NOTIFICATION_DAYS,
      },
      settings: {},
    };

    if (hasAlertsAccess) {
      features.alerts = {};
    }
    if (futuresVisible) {
      features.futures = {};
    }

    return poll(worker, {
      url: `v1/me/pollUpdate?features=${encodeURIComponent(
        JSON.stringify(features),
      )}`,
      interval: POLL_UPDATE_INTERVAL,
      onResponse: handlePollResponse,
    });
  }, [handlePollResponse, userLevel, worker, futuresVisible, loggedIn]);
};

export default usePollUpdate;
