import React, { useState, useMemo } from 'react';
import {
  Box,
  Button,
  Stack,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { TopNameItem } from 'types/optionsFeed';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ListIcon from '@mui/icons-material/List';

interface TopNamesListProps {
  items: TopNameItem[];
  headers?: string[];
  nameHeader?: string; // Header for the underlying column, defaults to 'Ticker'
}

const TopNamesList: React.FC<TopNamesListProps> = ({
  items,
  headers,
  nameHeader = 'Ticker',
}) => {
  // State to track the number of visible rows
  const [visibleCount, setVisibleCount] = useState<number>(5);

  // Handler to increment visibleCount by 5
  const handleSeeMore = () => {
    setVisibleCount((prev) => Math.min(prev + 5, items.length));
  };

  // Handler to reduce visible count back to initial 5
  const handleSeeLess = () => {
    setVisibleCount(5);
  };

  // Extract unique labels from all items if headers are not provided
  const dynamicHeaders = useMemo(() => {
    if (headers && headers.length > 0) {
      return headers;
    }

    const labelSet = new Set<string>();
    items.forEach((item) => {
      item.values.forEach((val) => labelSet.add(val.label));
    });
    return Array.from(labelSet);
  }, [items, headers]);

  // Determine which items to display based on visibleCount
  const displayedItems = useMemo(() => {
    return items.slice(0, visibleCount);
  }, [items, visibleCount]);

  // Determine if there are more items to show or if we can collapse
  const canSeeMore = visibleCount < items.length;
  const canSeeLess = visibleCount > 5;

  // Handle case when there are no items
  if (items.length === 0) {
    return (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <ListIcon />
        <Typography>No results</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <TableContainer
        component={Paper}
        sx={{ border: 'none' }}
        elevation={0} // Removes shadow
      >
        <Table size="small" aria-label="top names table">
          <TableHead>
            <TableRow>
              {/* Underlying Column */}
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  color: 'text.secondary',
                  backgroundColor: 'background.paper',
                  borderBottom: 'none',
                  padding: '6px 12px', // Reduced padding
                  whiteSpace: 'nowrap',
                }}
                align="left"
              >
                {nameHeader}
              </TableCell>
              {/* Dynamic Columns */}
              {dynamicHeaders.map((header: string, index: number) => (
                <TableCell
                  key={`${header}-${index}`}
                  align={header === nameHeader ? 'left' : 'right'}
                  sx={{
                    fontWeight: 'bold',
                    color: 'text.secondary',
                    backgroundColor: 'background.paper',
                    borderBottom: 'none',
                    padding: '6px 12px', // Reduced padding
                    whiteSpace: 'nowrap',
                  }}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {/* Render Displayed Items */}
            {displayedItems.map((item: TopNameItem, index: number) => (
              <TopNameTableRow
                key={`${item.underlying}-${index}`}
                item={item}
                headers={dynamicHeaders}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Expansion and Collapse Buttons */}
      <Stack
        direction="row"
        spacing={1}
        justifyContent="flex-end"
        sx={{ mt: 0.5 }}
      >
        {canSeeLess && (
          <Button
            size="small"
            onClick={handleSeeLess}
            startIcon={<ExpandLessIcon />}
            sx={{
              width: '100%',
              textTransform: 'none',
              fontSize: { xs: '0.75rem', md: '0.875rem' },
              padding: '4px 8px',
            }}
          >
            See Less
          </Button>
        )}
        {canSeeMore && (
          <Button
            size="small"
            onClick={handleSeeMore}
            startIcon={<ExpandMoreIcon />}
            sx={{
              width: '100%',
              textTransform: 'none',
              fontSize: { xs: '0.75rem', md: '0.875rem' },
              padding: '4px 8px',
            }}
          >
            See More
          </Button>
        )}
      </Stack>
    </Box>
  );
};

interface TopNameTableRowProps {
  item: TopNameItem;
  headers: string[];
}

const TopNameTableRow: React.FC<TopNameTableRowProps> = ({ item, headers }) => {
  const { underlying, values, icon } = item;

  // Create a map for quick lookup of values by label, including color
  const valuesMap = useMemo(() => {
    const map = new Map<string, { value: string; color?: string }>();
    values.forEach((val) =>
      map.set(val.label, { value: val.value, color: val.color }),
    );
    return map;
  }, [values]);

  return (
    <TableRow hover>
      {/* Underlying Cell with Icon */}
      <TableCell
        sx={{
          padding: '4px 8px',
          borderBottom: 'none',
        }}
        align="left"
      >
        <Stack direction="row" alignItems="center" spacing={0.5}>
          {icon}
          <Typography
            variant="body2"
            sx={{ color: 'text.primary', fontSize: '0.75rem' }}
          >
            {underlying}
          </Typography>
        </Stack>
      </TableCell>
      {/* Dynamic Value Cells */}
      {headers.map((header) => {
        const cellData = valuesMap.get(header);
        return (
          <TableCell
            key={header}
            align={header === 'Ticker' ? 'left' : 'right'}
            sx={{
              padding: '4px 8px',
              borderBottom: 'none',
            }}
          >
            {cellData ? (
              <Typography
                variant="body2"
                sx={{
                  color: cellData.color ?? 'text.primary',
                  fontSize: '0.75rem',
                  fontWeight: cellData.color ? 500 : 'normal',
                }}
              >
                {cellData.value}
              </Typography>
            ) : (
              <Typography
                variant="body2"
                sx={{ color: 'text.primary', fontSize: '0.75rem' }}
              >
                -
              </Typography>
            )}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default TopNamesList;
