import {
  LabelList,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip as RechartsTooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import { SearchHandlerFunction } from '../../../types';
import { useEffect, useMemo, useState } from 'react';
import { useLog, useSetSym } from '../../../hooks';
import { alpha, useTheme } from '@mui/material/styles';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  negativeTrendColorState,
  positiveTrendColorState,
  searchHandlerState,
  watchlistsState,
} from '../../../states';
import { difference } from 'lodash';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import { ComponentHeader, WatchlistMultiSelect } from '../../shared';
import SettingsPopout from '../../shared/SettingsPopout';
import { SizeControls } from '../../shared/SizeControls';
import { CompassPayload, StrategyCompassMode } from '../../../types/compass';
import {
  generateTooltipText,
  isValidCompassSymbol,
  getModeLabel,
} from '../../../util/compass';
import {
  compassScannerSymbolsState,
  scannerDisplayTypeState,
} from '../../../states/scanners';
import { ScannerDisplayType } from '../../../types/scanners';
import { selectedWatchlistsSymbols } from '../../../util/shared/watchlists';
import useToast from '../../../hooks/useToast';
import EditIcon from '@mui/icons-material/Edit';

const SPREAD_THRESHOLD = 0.25;
const transparent = 'rgba(0,0,0,0)';
const labelColor = '#a4a4a4';
const EDGE_ALLOWED_SPACING = 30;
const MAX_COMPASS_SYMS = 75;
const fontSize = 13;
const ALL_MODES = [StrategyCompassMode.PriceVol, StrategyCompassMode.SkewVol];

const StrategyCompassTooltip = ({
  active,
  payload,
}: TooltipProps<ValueType, NameType>) => {
  const theme = useTheme();

  if (active && payload && payload.length > 0) {
    const data = payload[0].payload;
    return (
      <Stack
        sx={{
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.text.primary,
          padding: '10px',
          borderRadius: '4px',
        }}
      >
        <Typography
          sx={{
            textAlign: 'center',
            textDecoration: 'underline',
            fontWeight: 'bold',
          }}
        >
          {data.sym}
        </Typography>
        <Stack sx={{ '*': { fontSize: '13px' } }}>
          <Typography>
            Bollinger Band %: {(data.x * 100.0).toFixed(2)}%
          </Typography>
          <Typography>IV Rank %: {(data.y * 100.0).toFixed(2)}%</Typography>
          <hr style={{ marginTop: '5px', marginBottom: '8px' }} />
          <Stack sx={{ maxWidth: '250px', gap: '5px' }}>
            {generateTooltipText(data)}
          </Stack>
        </Stack>
      </Stack>
    );
  }
  return null;
};

type StrategyCompassProps = {
  symbols?: string[];
};

export const StrategyCompass = ({ symbols }: StrategyCompassProps) => {
  const { sym } = useSetSym();
  const [mode, setMode] = useState<StrategyCompassMode>(
    StrategyCompassMode.PriceVol,
  );
  const [data, setData] = useState<{
    [StrategyCompassMode.PriceVol]: CompassPayload[];
    [StrategyCompassMode.SkewVol]: CompassPayload[];
  }>({
    [StrategyCompassMode.PriceVol]: [],
    [StrategyCompassMode.SkewVol]: [],
  });
  const dataForMode = data[mode];
  const [syms, setSyms] = useState<string[]>([]);
  const [activeWatchlistIds, setActiveWatchlistIds] = useState<number[]>([]);

  const X_MIN =
    mode === StrategyCompassMode.PriceVol
      ? 0
      : Math.min(-0.2, Math.min(...dataForMode.map((d) => d.x)));
  const X_MAX =
    mode === StrategyCompassMode.PriceVol
      ? 1
      : Math.max(0.2, Math.max(...dataForMode.map((d) => d.x)));
  const SEGMENT_PADDING = X_MAX * 0.02;
  const Y_MIN = 0;
  const Y_MAX = 1;
  const X_MID = (X_MIN + X_MAX) / 2.0;
  const Y_MID = (Y_MIN + Y_MAX) / 2.0;
  // add some buffer because points at the exact min/max are not visible on the plot
  const X_DOMAIN = [X_MIN - X_MAX * 0.035, X_MAX + X_MAX * 0.035];
  const Y_DOMAIN = [Y_MIN - Y_MAX * 0.035, Y_MAX + Y_MAX * 0.035];

  const { fetchAPIWithLog } = useLog('StrategyCompass');

  const editable = useMemo(() => symbols == null, [symbols]);
  const [sizeOptionsOpen, setSizeOptionsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const theme = useTheme();
  const setSuggestionsHandler = useSetRecoilState(searchHandlerState);
  const axisTextColor = theme.palette.text.secondary;
  const positiveTrend = useRecoilValue(positiveTrendColorState);
  const negTrend = useRecoilValue(negativeTrendColorState);
  const setScannerDisplayState = useSetRecoilState(scannerDisplayTypeState);
  const watchlists = useRecoilValue(watchlistsState);
  const [compassScannerSymbols, setCompassScannerSymbols] = useRecoilState(
    compassScannerSymbolsState,
  );
  const [chartSize, setChartSize] = useState<{ width: number; height: number }>(
    { width: 0, height: 0 },
  );

  const { openToast } = useToast();

  const shadeColor = alpha(theme.palette.text.primary, 0.1); // light shade color

  const deleteSyms = (symsToDelete: string[]) => {
    if (!editable) {
      return;
    }

    const symToDeleteSet = new Set(symsToDelete);
    setSyms((enabledSyms) => enabledSyms.filter((s) => !symToDeleteSet.has(s)));
  };

  const resetAll = () => {
    setActiveWatchlistIds([]);
    setSyms([]);
    setCompassScannerSymbols([]);
    setData({
      [StrategyCompassMode.PriceVol]: [],
      [StrategyCompassMode.SkewVol]: [],
    });
  };

  const addSyms = (symsToAdd: string[]) => {
    if (!editable) {
      return;
    }

    const newSyms = [
      ...new Set(syms.concat(symsToAdd).filter(isValidCompassSymbol)).values(),
    ];

    if (newSyms.length === syms.length) {
      return;
    }

    fetchData(newSyms);
  };

  useEffect(() => {
    if (syms.length === 0) {
      return;
    }
    fetchData(syms);
  }, [mode]);

  useEffect(() => {
    addSyms([sym]);
  }, [sym]);

  useEffect(() => {
    addSyms(compassScannerSymbols);
  }, [compassScannerSymbols]);

  async function fetchData(symsToAdd: string[] = []) {
    let newSyms = difference(symsToAdd, syms).filter(isValidCompassSymbol);
    if (newSyms.length === 0) {
      return;
    } else if (newSyms.length + syms.length > MAX_COMPASS_SYMS) {
      const remaining = MAX_COMPASS_SYMS - syms.length;
      openToast({
        type: 'warning',
        message: `You can only display up to ${MAX_COMPASS_SYMS} symbols at once. 
        Please delete some symbols using the settings icon in the toolbar above before adding more symbols.`,
      });
      if (remaining <= 0) {
        return;
      }

      newSyms = newSyms.slice(0, remaining);
    }

    setSyms((enabledSyms) => [
      ...new Set(enabledSyms.concat(newSyms)).values(),
    ]);

    // it's possible we've fetched data for these symbols already and they are just being re-enabled
    // if so, dont fetch again
    const currSymsFetched = dataForMode.map((d) => d.sym);
    const newSymsToFetch = difference(symsToAdd, currSymsFetched).filter(
      isValidCompassSymbol,
    );
    if (newSymsToFetch.length === 0) {
      return;
    }
    setLoading(true);
    const fetchedData = await fetchAPIWithLog(
      `v1/compass?syms=${encodeURIComponent(newSymsToFetch.join(','))}&x=${
        mode === StrategyCompassMode.PriceVol ? 'price' : 'skew'
      }`,
    );
    setLoading(false);

    if (
      fetchedData.error != null ||
      !Array.isArray(fetchedData) ||
      fetchedData.length === 0
    ) {
      openToast({
        message: 'There was an error fetching compass data. Please try again.',
        type: 'error',
      });
      return;
    }
    setData({ ...data, [mode]: dataForMode.concat(fetchedData) });
  }

  const chipsComponent = (
    <Box marginX="15px">
      {syms.map((s) => {
        return (
          <Chip
            label={s}
            key={s}
            onDelete={sym === s ? undefined : () => deleteSyms([s])}
            sx={{ marginX: '2px', marginY: '2px' }}
          />
        );
      })}
    </Box>
  );

  const axisLinesComponent = (
    <>
      <ReferenceLine
        segment={[
          { x: X_MIN - SEGMENT_PADDING, y: Y_MID },
          { x: X_MAX + SEGMENT_PADDING, y: Y_MID },
        ]}
        stroke={theme.palette.text.primary}
        label={(_props) => (
          <>
            <text
              x={chartSize.width / 2.0 - 55}
              y={8}
              fill={axisTextColor}
              fontSize={11}
            >
              {renderLabelText('Implied Volatility Rank %')}
            </text>
            <text
              x={chartSize.width / 2.0 - 60}
              y={21}
              fill={axisTextColor}
              fontSize={11}
            >
              {renderLabelText('↑ higher IV')}
            </text>
            <text
              x={chartSize.width / 2.0 + 5}
              y={21}
              fill={axisTextColor}
              fontSize={11}
            >
              {renderLabelText('↓ lower IV')}
            </text>
          </>
        )}
      />
      <ReferenceLine
        segment={[
          { x: X_MID, y: Y_MIN - SEGMENT_PADDING },
          { x: X_MID, y: Y_MAX + SEGMENT_PADDING },
        ]}
        stroke={theme.palette.text.primary}
        label={(_props) => (
          <>
            <text
              x={10}
              y={chartSize.height / 2.0 - 10}
              fill={axisTextColor}
              fontSize={11}
            >
              {renderLabelText(
                mode === StrategyCompassMode.PriceVol
                  ? 'Bollinger Band %'
                  : '25 Delta Skew (1m Put-Call)',
              )}
            </text>
            <text
              x={10}
              y={chartSize.height / 2.0 + 15}
              fill={axisTextColor}
              fontSize={11}
            >
              {renderLabelText(
                mode === StrategyCompassMode.PriceVol
                  ? '← Bullish, Bearish →'
                  : '← Lower, Higher →',
              )}
            </text>
          </>
        )}
      />
    </>
  );

  const labeledReferenceAreas = mode === StrategyCompassMode.PriceVol && (
    <>
      <ReferenceArea
        x1={X_MID - SPREAD_THRESHOLD}
        y1={Y_MID - SPREAD_THRESHOLD}
        x2={X_MID + SPREAD_THRESHOLD}
        y2={Y_MID + SPREAD_THRESHOLD}
        fill={shadeColor}
      />
      <ReferenceArea
        x1={X_MID}
        y1={Y_MID}
        x2={X_MID - SPREAD_THRESHOLD}
        y2={Y_MID - SPREAD_THRESHOLD}
        fill={transparent}
        ifOverflow="visible"
        label={(_props) => (
          <text
            x={chartSize.width * 0.33}
            y={chartSize.height * 0.62}
            fill={labelColor}
            fontSize={fontSize}
          >
            {renderLabelText('Buy Call Spread')}
          </text>
        )}
      />
      <ReferenceArea
        x1={X_MID}
        y1={Y_MID}
        x2={X_MID - SPREAD_THRESHOLD}
        y2={Y_MID + SPREAD_THRESHOLD}
        fill={transparent}
        ifOverflow="visible"
        label={(_props) => (
          <text
            x={chartSize.width * 0.33}
            y={chartSize.height * 0.4}
            fill={labelColor}
            fontSize={fontSize}
          >
            {renderLabelText('Sell Put Spread')}
          </text>
        )}
      />
      <ReferenceArea
        x1={X_MID}
        y1={Y_MID}
        x2={X_MID + SPREAD_THRESHOLD}
        y2={Y_MID + SPREAD_THRESHOLD}
        fill={transparent}
        ifOverflow="visible"
        label={(_props) => (
          <text
            x={chartSize.width * 0.56}
            y={chartSize.height * 0.4}
            fill={labelColor}
            fontSize={fontSize}
          >
            {renderLabelText('Sell Call Spread')}
          </text>
        )}
      />
      <ReferenceArea
        x1={X_MID}
        y1={Y_MID}
        x2={X_MID + SPREAD_THRESHOLD}
        y2={Y_MID - SPREAD_THRESHOLD}
        fill={transparent}
        ifOverflow="visible"
        label={(_props) => (
          <text
            x={chartSize.width * 0.56}
            y={chartSize.height * 0.62}
            fill={labelColor}
            fontSize={fontSize}
          >
            {renderLabelText('Buy Put Spread')}
          </text>
        )}
      />
      <ReferenceArea
        x1={X_MID}
        y1={Y_MID}
        x2={X_MIN}
        y2={Y_MIN}
        fill={shadeColor}
        ifOverflow="visible"
        label={(_props) => (
          <text
            x={chartSize.width * 0.15}
            y={chartSize.height * 0.8}
            fill={labelColor}
            fontSize={fontSize}
          >
            {renderLabelText('Buy Call')}
          </text>
        )}
      />
      <ReferenceArea
        x1={X_MID}
        y1={Y_MID}
        x2={X_MIN}
        y2={Y_MAX}
        fill={shadeColor}
        ifOverflow="visible"
        label={(_props) => (
          <text
            x={chartSize.width * 0.15}
            y={chartSize.height * 0.2}
            fill={labelColor}
            fontSize={fontSize}
          >
            {renderLabelText('Sell Put')}
          </text>
        )}
      />
      <ReferenceArea
        x1={X_MID}
        y1={Y_MID}
        x2={X_MAX}
        y2={Y_MAX}
        fill={shadeColor}
        ifOverflow="visible"
        label={(_props) => (
          <text
            x={chartSize.width * 0.8}
            y={chartSize.height * 0.2}
            fill={labelColor}
            fontSize={fontSize}
          >
            {renderLabelText('Sell Call')}
          </text>
        )}
      />
      <ReferenceArea
        x1={X_MID}
        y1={Y_MID}
        x2={X_MAX}
        y2={Y_MIN}
        fill={shadeColor}
        ifOverflow="visible"
        label={(_props) => (
          <text
            x={chartSize.width * 0.8}
            y={chartSize.height * 0.8}
            fill={labelColor}
            fontSize={fontSize}
          >
            {renderLabelText('Buy Put')}
          </text>
        )}
      />
      <ReferenceArea
        x1={X_MID}
        y1={Y_MIN}
        x2={X_MID}
        y2={Y_MIN}
        fill={transparent}
        ifOverflow="visible"
        label={(_props) => (
          <>
            <text
              x={chartSize.width / 2.0 - 70}
              y={chartSize.height - 80}
              fill={labelColor}
              fontSize={fontSize}
            >
              {renderLabelText('Buy Straddle / Strangle')}
            </text>
          </>
        )}
      />
      <ReferenceArea
        x1={X_MID}
        y1={Y_MAX}
        x2={X_MID}
        y2={Y_MAX}
        fill={transparent}
        ifOverflow="visible"
        label={(_props) => (
          <>
            <text
              x={chartSize.width / 2.0 - 70}
              y={80}
              fill={labelColor}
              fontSize={fontSize}
            >
              {renderLabelText('Sell Straddle / Strangle')}
            </text>
          </>
        )}
      />
    </>
  );

  useEffect(() => {
    const watchlistSyms = selectedWatchlistsSymbols(
      watchlists,
      activeWatchlistIds,
    );
    addSyms(watchlistSyms);
  }, [activeWatchlistIds]);

  useEffect(() => {
    if (!editable) {
      return;
    }
    const suggestionsHandler: SearchHandlerFunction = (value: string): void => {
      addSyms([value]);
    };

    setSuggestionsHandler(() => suggestionsHandler);
  }, [editable]);

  function renderLabelText(str: string) {
    return chartSize.width > 0 && chartSize.height > 0 ? str : '';
  }

  return (
    <Box width={1} height={1}>
      {editable && (
        <ComponentHeader
          buttons={
            <>
              {loading || chartSize.width === 0 ? (
                <CircularProgress
                  size="18px"
                  color="primary"
                  sx={{ marginRight: '5px' }}
                />
              ) : null}
              <Button
                size="small"
                sx={{
                  textTransform: 'capitalize',
                  fontSize: 13,
                }}
                onClick={(_event) => {
                  setScannerDisplayState(ScannerDisplayType.Scanners);
                }}
              >
                Close
              </Button>
              <SizeControls
                open={sizeOptionsOpen}
                setOpen={setSizeOptionsOpen}
              />

              <SettingsPopout
                title={'Settings'}
                popperID={'strategy-compass-settings'}
              >
                <Typography fontSize="13" marginRight="5px">
                  Mode:{' '}
                </Typography>
                <Select
                  sx={{
                    '.MuiSelect-icon': {
                      color: theme.palette.text.primary,
                    },
                    paddingY: '0px',
                  }}
                  value={mode}
                  onChange={async (e) =>
                    setMode(e.target.value as StrategyCompassMode)
                  }
                >
                  {ALL_MODES.map((m) => (
                    <MenuItem value={m} key={m}>
                      {getModeLabel(m)}
                    </MenuItem>
                  ))}
                </Select>
              </SettingsPopout>
              <SettingsPopout
                title={'Edit Symbols'}
                popperID={'strategy-compass-edit-symbols'}
                icon={<EditIcon />}
              >
                <Button
                  size="small"
                  sx={{
                    textTransform: 'capitalize',
                    fontSize: 13,
                  }}
                  onClick={resetAll}
                >
                  Reset All
                </Button>
                {chipsComponent}
              </SettingsPopout>
              <WatchlistMultiSelect
                activeWatchlistIds={activeWatchlistIds}
                setActiveWatchlistIds={(val) => {
                  const diff = difference(activeWatchlistIds, val);
                  if (diff.length > 0) {
                    deleteSyms(selectedWatchlistsSymbols(watchlists, diff));
                  }
                  setActiveWatchlistIds(val);
                }}
                title={'Show symbols in watchlist'}
              />
            </>
          }
        />
      )}

      <Box width={1} height={'calc(100% - 50px)'}>
        <ResponsiveContainer
          onResize={(width, height) => setChartSize({ width, height })}
        >
          <ScatterChart>
            <XAxis
              type="number"
              dataKey="x"
              name={mode === StrategyCompassMode.PriceVol ? 'Price' : 'Skew'}
              hide
              domain={X_DOMAIN}
            />
            <YAxis
              type="number"
              hide
              dataKey="y"
              name="Implied Volatility"
              domain={Y_DOMAIN}
            />

            {axisLinesComponent}

            <defs>
              <linearGradient id={'full_gradient'}>
                <stop
                  offset="0%"
                  stopColor={
                    mode === StrategyCompassMode.PriceVol
                      ? positiveTrend
                      : theme.palette.primary.main
                  }
                  stopOpacity={0.45}
                />
                <stop
                  offset="100%"
                  stopColor={
                    mode === StrategyCompassMode.PriceVol
                      ? negTrend
                      : theme.palette.background.paper
                  }
                  stopOpacity={0.45}
                />
              </linearGradient>
            </defs>

            <ReferenceArea
              x1={X_MIN}
              y1={Y_MIN}
              x2={X_MAX}
              y2={Y_MAX}
              fill={`url(#full_gradient)`}
              stroke={'rgba(0,0,0,0)'}
              fillOpacity={1}
              strokeWidth={0}
              strokeOpacity={0}
            />

            {labeledReferenceAreas}

            <Scatter
              data={dataForMode.filter((d) => new Set(syms).has(d.sym))}
              fill="#8884d8"
              isAnimationActive={false}
            >
              <LabelList
                dataKey="sym"
                content={(props: any) => {
                  let x = props.x - 1.75 * props.value.length;
                  let y = props.y - 2;
                  let forceRight = props.x <= EDGE_ALLOWED_SPACING;
                  let forceLeft =
                    props.x >= chartSize.width - EDGE_ALLOWED_SPACING;

                  if (forceLeft || forceRight) {
                    // if point is at the extreme left/right, position label next to point
                    y += 10;
                    x += forceRight ? 17 : -5.5 * props.value.length;
                  }
                  if (props.y <= EDGE_ALLOWED_SPACING) {
                    // if point is at the top, position label below point
                    y += 20;
                  }

                  return (
                    <text x={x} y={y} fontSize={11} fill={'#fff'}>
                      {props.value}
                    </text>
                  );
                }}
              />
            </Scatter>
            <RechartsTooltip
              isAnimationActive={false}
              content={
                mode === StrategyCompassMode.PriceVol ? (
                  <StrategyCompassTooltip />
                ) : undefined
              }
            />
          </ScatterChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};
