import { Stack, Typography } from '@mui/material';

interface SummaryCardWrapperProps {
  title: string;
  icon?: JSX.Element;
  children: JSX.Element;
}

const SummaryCardWrapper = ({
  title,
  icon,
  children,
}: SummaryCardWrapperProps) => {
  return (
    <Stack
      sx={{
        width: '100%',
        alignItems: 'center',
      }}
    >
      <Stack direction="row" sx={{ gap: 1 }}>
        {icon}
        <Typography
          sx={{
            color: 'text.secondary',
            fontSize: { xs: 10, md: 12 },
            textTransform: 'uppercase',
            textAlign: 'center',
          }}
        >
          {title}
        </Typography>
      </Stack>

      {children}
    </Stack>
  );
};

export default SummaryCardWrapper;
