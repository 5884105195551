import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { alpha, Divider, Skeleton, Stack, useTheme } from '@mui/material';
import SummaryCardWrapper from './SummaryCardWrapper';
import { useRecoilState, useRecoilValue } from 'recoil';
import SGAccordion from 'components/SGAccordion';
import TopNamesList from './cards/TopNamesList';
import useTnsFeed from 'hooks/optionsFeed/useTnsFeed';
import BarChartIcon from '@mui/icons-material/BarChart';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import {
  TnsFlowHighlights,
  TopNameItem,
  BasicHighlight,
  PremiumHighlight,
  MoverHighlight,
} from 'types/optionsFeed';
import poll from 'util/poll';
import { formatAsCompactNumber, formatAsCurrency } from 'util/shared';
import useToast from 'hooks/useToast';
import {
  negativeTrendColorState,
  positiveTrendColorState,
  workerState,
} from 'states';
import { tnsDailyOverviewExpandedState } from 'states/optionsFeed';
import { STREAM_HOST_URL } from 'config/shared';

const DividerElement: React.FC = () => {
  const theme = useTheme();
  return (
    <Divider
      flexItem
      orientation="vertical"
      sx={{ borderColor: alpha(theme.palette.text.secondary, 0.35) }}
    />
  );
};

const LoadingOverlay: React.FC = () => (
  <Stack
    sx={{
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      overflowX: 'auto',
      width: '100%',
      gap: 4,
    }}
  >
    <ListLoadingOverlay />
    <DividerElement />
    <ListLoadingOverlay />
    <DividerElement />
    <ListLoadingOverlay />
    <DividerElement />
    <ListLoadingOverlay />
  </Stack>
);

const ListLoadingOverlay: React.FC = () => (
  <Stack sx={{ gap: 2, width: '100%', alignItems: 'center' }}>
    <Skeleton variant="text" sx={{ width: 80 }} />
    <Skeleton variant="rectangular" sx={{ width: '100%', minWidth: 120 }} />
    <Skeleton variant="rectangular" sx={{ width: '100%', minWidth: 120 }} />
    <Skeleton variant="rectangular" sx={{ width: '100%', minWidth: 120 }} />
    <Skeleton variant="rectangular" sx={{ width: '100%', minWidth: 120 }} />
    <Skeleton variant="rectangular" sx={{ width: '100%', minWidth: 120 }} />
  </Stack>
);

const DailyFlowOverview: React.FC = () => {
  const theme = useTheme();
  const { openToast } = useToast();
  const [expanded, setExpanded] = useRecoilState(tnsDailyOverviewExpandedState);
  const worker = useRecoilValue(workerState);
  const [loading, setLoading] = useState<boolean>(false);
  const [flowHighlightsData, setFlowHighlights] =
    useState<TnsFlowHighlights | null>(null);
  const negColor = useRecoilValue(negativeTrendColorState);
  const posColor = useRecoilValue(positiveTrendColorState);

  const { fetchFlowHighlights } = useTnsFeed();

  const handleResponse = useCallback(
    async (response: any) => {
      if (response?.json != null) {
        setFlowHighlights(response.json);
      }
    },
    [setFlowHighlights],
  );

  // Initial Data Fetch
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await fetchFlowHighlights();
        setFlowHighlights(res);
      } catch (err: any) {
        console.error(err);
        openToast({
          message: err.message,
          type: 'error',
          duration: 7000,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Polling for Updates
  useEffect(() => {
    return poll(worker, {
      url: `sg/tns_highlights`,
      interval: 30_000, // poll every 30s
      host: STREAM_HOST_URL,
      onResponse: handleResponse,
    });
  }, [worker, handleResponse]);

  const mappedData = useMemo(() => {
    if (!flowHighlightsData) {
      return null;
    }

    const mapBasicHighlightToTopNameItem = (
      highlight: BasicHighlight,
    ): TopNameItem => ({
      underlying: highlight.underlying || 'N/A',
      values: [
        { label: 'Volume', value: formatAsCompactNumber(highlight.val) },
      ],
    });

    const mapPremiumHighlightToTopNameItem = (
      highlight: PremiumHighlight,
    ): TopNameItem => ({
      underlying: highlight.underlying || 'N/A',
      values: [
        {
          label: 'Premium',
          value: `$${formatAsCompactNumber(highlight.premium)}`,
        },
        {
          label: 'Expiry',
          value: new Date(highlight.expiry).toLocaleDateString(),
        },
        { label: 'Strike', value: `${formatAsCurrency(highlight.strike)}` },
        { label: 'Type', value: highlight.is_put ? 'Put' : 'Call' },
      ],
    });

    const mapMoverHighlightToTopNameItem = (
      highlight: MoverHighlight,
    ): TopNameItem => {
      const lastClose = highlight.last_close;
      const close = highlight.close;

      // Calculate percentage change, handling division by zero
      const changePercent =
        lastClose !== 0 ? ((close - lastClose) / lastClose) * 100 : 0;

      // Format the percentage to two decimal places and append '%'
      const formattedChangePercent = `${changePercent.toFixed(2)}%`;

      return {
        underlying: highlight.underlying || 'N/A',
        values: [
          {
            label: 'Last Close',
            value: `${formatAsCurrency(lastClose)}`,
          },
          { label: 'Price', value: `${formatAsCurrency(close)}` },
          {
            label: 'Change %',
            value: formattedChangePercent,
            color: changePercent < 0 ? negColor : posColor,
          },
        ],
      };
    };

    return {
      volume: flowHighlightsData.volume.map(mapBasicHighlightToTopNameItem),
      gamma: flowHighlightsData.gamma.map(mapBasicHighlightToTopNameItem),
      movers: flowHighlightsData.movers.map(mapMoverHighlightToTopNameItem),
      premium: flowHighlightsData.premium.map(mapPremiumHighlightToTopNameItem),
    };
  }, [flowHighlightsData, negColor, posColor]);

  return (
    <SGAccordion
      hideSummaryWhenExpanded
      title="Market Highlights"
      icon={<TrendingUpIcon sx={{ color: theme.palette.primary.main }} />}
      expanded={expanded}
      onChange={() => setExpanded((prev) => !prev)}
      paperSx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        overflowX: 'auto',
      }}
    >
      {loading ? (
        <LoadingOverlay />
      ) : (
        mappedData && (
          <>
            {/* Top Volume */}
            <SummaryCardWrapper
              title="Top Volume (first hour)"
              icon={
                <BarChartIcon sx={{ fontSize: 18, color: 'text.secondary' }} />
              }
            >
              <TopNamesList items={mappedData.volume} nameHeader="Ticker" />
            </SummaryCardWrapper>

            <DividerElement />

            {/* Top Daily Gamma Notional */}
            <SummaryCardWrapper
              title="Top Daily Gamma Notional"
              icon={
                <ShowChartIcon sx={{ fontSize: 16, color: 'text.secondary' }} />
              }
            >
              <TopNamesList items={mappedData.gamma} nameHeader="Ticker" />
            </SummaryCardWrapper>

            <DividerElement />

            {/* Top Daily Movers */}
            <SummaryCardWrapper
              title="Top Daily Movers"
              icon={
                <SwapVertIcon sx={{ fontSize: 16, color: 'text.secondary' }} />
              }
            >
              <TopNamesList items={mappedData.movers} nameHeader="Ticker" />
            </SummaryCardWrapper>

            <DividerElement />

            {/* Largest Daily Trades */}
            <SummaryCardWrapper
              title="Largest Daily Trades"
              icon={
                <MonetizationOnIcon
                  sx={{ fontSize: 16, color: 'text.secondary' }}
                />
              }
            >
              <TopNamesList items={mappedData.premium} nameHeader="Ticker" />
            </SummaryCardWrapper>
          </>
        )
      )}
    </SGAccordion>
  );
};

export default DailyFlowOverview;
