import { PreferencesTab } from 'types';
import { dayjs, ET, getCachedToken, isBloomberg } from '../util';
import { Dayjs } from 'dayjs';

export const EDITABLE_ACCOUNT_DETAILS = isBloomberg()
  ? []
  : [
      {
        field: 'first_name',
        label: 'First Name',
        type: 'string',
      },
      {
        field: 'last_name',
        label: 'Last Name',
        type: 'string',
      },
      {
        field: 'email',
        label: 'Email',
        type: 'email',
      },
    ];

export const PREFERENCES_LABEL_MAPPING = new Map(
  isBloomberg()
    ? [
        [PreferencesTab.Account, 'Account'],
        [PreferencesTab.Colors, 'Colors'],
        [PreferencesTab.Alerts, 'Alerts'],
      ]
    : [
        [PreferencesTab.Account, 'Account'],
        [PreferencesTab.Colors, 'Colors'],
        [PreferencesTab.Payments, 'Payments'],
        [PreferencesTab.Watchlists, 'Watchlists'],
        [PreferencesTab.Alerts, 'Alerts'],
      ],
);

// putting everything in one place so it can be easily removed later

export type TokenTimeframe = {
  minDate: Dayjs;
  maxDate: Dayjs;
  token: string;
};

export const TRACE_TOKEN_OVERRIDES: TokenTimeframe[] = [
  {
    minDate: dayjs.tz('2024-10-10 00:00', ET),
    maxDate: dayjs.tz('2024-10-14 23:59:59', ET),
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJTcG90R2FtbWEiLCJ1c2VyX2lkIjoiOThlM2IzYzMtODExMy00ZGIzLTg3MGItMDA2ZTJiZWEwNzI2IiwiaXNfaW5zdGl0dXRpb25hbCI6bnVsbCwid3BJRCI6MTY2MTEsImVtYWlsIjoiZW5nK2FscGhhQHNwb3RnYW1tYS5jb20iLCJtZW1iZXJzaGlwcyI6WyJBbHBoYSJdLCJyb2xlcyI6WyJzdWJzY3JpYmVyIl0sInVzZXJUeXBlIjoxLCJ3cFRva2VuIjp7ImFjY2Vzc190b2tlbiI6InN0eXpmcXNqZGZma2FwNmp4cGwycm10dzZva2N2YTAyZ25jc3Q1NHQiLCJleHBpcmVzX2luIjoyNTkyMDAsInRva2VuX3R5cGUiOiJCZWFyZXIiLCJzY29wZSI6ImJhc2ljIiwicmVmcmVzaF90b2tlbiI6IjdrYnluZWZueGl2aXh5anE1YnJ2ZG9qcnJuOTVhOXNpZGNyMjNzd2gifSwiaWF0IjoxNzI4NDk1NDMzLCJleHAiOjE3Mjg5NjQ4MDB9.Wq4RSSn7uPSqBvq7hF88HArVVnt7efofNjDsq3Tqc-Q',
  },
];
