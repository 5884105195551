import { EquityColumnGroup } from '../../types/equityhub';
import { OCCCategory } from '../../types/sentiment';

export const lightModeTheme = {
  palette: {
    sgGreen: '#00BBAA',
    background: {
      default: '#ffffff',
      paper: '#fafafa',
    },
    text: {
      primary: '#000000',
      secondary: 'rgba(0, 0, 0, 0.7)',
      disabled: 'rgba(0, 0, 0, 0.5)',
    },
    gray: '#87888a',
    shadows: {
      default: '1px 1px 3px #bdbdbd',
      paperBoxShadow: '1px 1px 3px #bdbdbd',
    },
    button: {
      default: '#e9bdbe',
      hover: '#cfa3a4',
    },
    core: {
      price: '#7c94eb',
      put: '#1f77b4',
      call: '#ff7f0e',
      translucentBG: 'rgba(240, 240, 240, 0.8)',
    },
    equityHub: {
      fieldColorMapping: {
        upx: '#a5b9ff',
        maxfs: '#ff9ac5',
        keyg: '#fca34a',
        keyd: '#22d92d',
        putctrl: '#fffb00',
        cws: '#22e3ec',
        pws: '#ff5860',
      },
      skew: {
        allFirst: '#270088',
        allSecond: '#270088',
        nextExpFirst: '#30791f',
        nextExpSecond: '#30791f',
      },
      putCallImpact: {
        nextExpGex: '#118d84',
        allExpGex: '#6467de',
      },
    },
    equityHubColumns: {
      [EquityColumnGroup.STATISTICS]: '#000000',
      [EquityColumnGroup.SG_KEY_DAILY_LEVELS]: '#49a698',
      [EquityColumnGroup.DIRECTIONAL_INDICATORS]: '#a3a3a2',
      [EquityColumnGroup.VOLATILITY_INSIGHTS]: '#b59c4a',
      [EquityColumnGroup.DARK_POOL_INDICATORS]: '#b5674a',
    },
    scannerGrid: {
      mainHeader: '#ebebeb',
      pinnedColumn: '#ebebeb',
      pinnedRow: '#e0f7f6',
    },
    alerts: {
      hiroReversionTop: '#d97f5d',
      hiroReversionBottom: '#18bdac',
    },
    hiro: {
      signalBg: '#e5e5e5',
      flow: '#5cd09e',
      indices: {
        SPY: '#1eab77',
        QQQ: '#23bbc9',
        IWM: '#cb9325',
      },
      bottomCandles: {
        absolute: '#818181',
      },
      lenses: {
        all: {
          call: '#ff5800',
          put: '#0012b4',
          total: '#6467de',
        },
        retail: {
          call: '#a90680',
          put: '#ff0000',
          total: '#ff5e5e',
        },
        nextExp: {
          call: '#30aF20',
          put: '#0081c5',
          total: '#118d84',
        },
        price: {
          total: '#424242',
        },
      },
      priceLines: {
        upx: '#a0a0a0',
        keyg: '#83deff',
        keyd: '#8ea3de',
        cws: '#3aa0ff',
        pws: '#c88070',
        maxfs: '#dd3333',
        sig_high: '#c334d2',
        sig_low: '#c334d2',
      },
      grid: {
        lineColor: '#d2d2d2',
      },
    },
    sentiment: {
      occ: {
        [OCCCategory.EQUITY_CALLS]: '#e377c2',
        [OCCCategory.EQUITY_PUTS]: '#7f7f7f',
        [OCCCategory.ETF_CALLS]: '#bcbd22',
        [OCCCategory.ETF_PUTS]: '#17becf',
        [OCCCategory.INDEX_CALLS]: '#1f77b4',
        [OCCCategory.INDEX_PUTS]: '#ff7f0e',
      },
      historical: {
        upx: '#a0a0a0',
        max_g_strike: '#a1772a',
        put_wall_strike: '#307b8a',
        call_wall_strike: '#913160',
        gamma_index: '#228fe8',
      },
    },
    indices: {
      combos: {
        gamma: '#d06207',
      },
      delta: {
        all: '#737373',
        nextExpMonthly: '#8a6f38',
        nextExp: '#914d3c',
      },
      gammaVanna: {
        gamma: '#2d876d',
        vanna: '#653285',
        delta: '#785329',
        volforecast: '#358c4c',
        nextExp: '#737373',
      },
      tilt: {
        upx: '#000000',
        gamma: '#0683A1',
        delta: '#465F94',
      },
      zeroDte: {
        volume: '#0683A1',
        oi: '#9f1ebb',
        oi_avg: '#000000',
      },
      equityRatio: {
        volume: '#383838',
        oi: '#166d8a',
      },
      vol: {
        five_day_vol: '#FF0000',
        one_month_vol: '#00FF00',
        two_month_vol: '#0000FF',
        three_month_vol: '#FFA500',
        six_month_vol: '#000000',
      },
      maxVol: {
        price: '#000000',
        two_six_month: '#653285',
        one_three_month: '#785329',
      },
    },
    iVol: {
      termStructure: {
        date0: '#00BBAA',
        date1: '#7f7f7f',
        date2: '#d5d708',
        date3: '#48dceb',
        date4: '#1f77b4',
        date5: '#ff7f0e',
      },
      volSkew: {
        date0: '#00BBAA',
        date1: '#7f7f7f',
        date2: '#d5d708',
        date3: '#48dceb',
        date4: '#1f77b4',
        date5: '#ff7f0e',
      },
      fixedStrikeMatrix: {
        grayCell: '#CBCDCE',
        lowValueCell: '#fafafa',
        highValueCell: '#18BDAC',
        highlight: '#ef5f0e',
      },
    },
    resources: {
      earnings: {
        bmo: '#27b0e6',
        amc: '#841088',
        other: '#6a6a6a',
        label: '#212121',
      },
    },
    compass: {
      buttonColor: '#841088',
    },
    trace: {
      heatmapSettings: {
        hiroColor: '#4f51b7',
        chartZeroColor: '#fff',
        candleUpFillColor: '#fff',
        candleDownFillColor: '#343434',
        candleUpLineColor: '#a0a0a0',
        candleDownLineColor: '#575757',
        contourLineColor: '#414141',
        callColor: '#d8723a',
        putColor: '#3a79d8',
        lastCandleColor: '#000000',
        posHeatmapColor: '#771ee8',
        negHeatmapColor: '#f51257',
        posStrikeColor: '#771ee8',
        negStrikeColor: '#f51257',
      },
      strikeBarSettings: {
        tracker: {
          last: '#000000',
          thirtyMin: '#e8bc10',
          hour: '#0cead0',
          dailyMax: '#858585',
          dailyMin: '#858585',
        },
      },
      updateStatusColor: '#aaaaaa',
    },
  },
};
