import { atom, selector } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import {
  IntradayGammaLense,
  IntradayStrikeBarType,
  OIEntity,
  OIScaleRange,
  OiTab,
  PRICE_BOUNDS,
  STRIKE_ZOOM_BOUNDS,
} from '../types';
import { InitOutput } from 'parquet-wasm';
import { userSettingsState } from './auth';
import { HEATMAP_DEFAULT_SPX_HIRO_SYM } from '../config/oi';
import { themeState } from './shared';

const { persistAtom } = recoilPersist();

export const oiSymsState = atom<Set<string>>({
  key: 'oi-syms',
  default: new Set(),
});

export const oiIntradayParquetKeys = atom<OIEntity[]>({
  key: 'oi-intraday-gamma-keys',
  default: [OIEntity.CUST],
});

export const oiIntradayPriceBoundsState = selector<number>({
  key: 'oi-price-bounds',
  get: ({ get }) => {
    const settings = get(userSettingsState);
    return settings?.oi?.zoom ?? PRICE_BOUNDS[6];
  },
});

export const oiIntradayStrikeBoundsState = selector<number>({
  key: 'oi-strike-zoom-bounds',
  get: ({ get }) => {
    const settings = get(userSettingsState);
    return settings?.oi?.strikeZoom ?? STRIKE_ZOOM_BOUNDS[0];
  },
});

export const oiIntradayInvertedState = atom<boolean>({
  key: 'oi-intraday-inverted',
  default: true,
});

export const oiIntradayFilterPrice = atom<boolean>({
  key: 'oi-intraday-filter-price',
  default: true,
});

export const oiTabState = atom<OiTab>({
  key: 'oi-tab',
  default: OiTab.DAILY_OI,
  effects_UNSTABLE: [persistAtom],
});

export const parquetWasmInitPromise = atom<Promise<InitOutput> | null>({
  key: 'parquet-wasm-init-promise',
  default: null,
});

export const oiPriceCandleDurationState = selector<number>({
  key: 'oi-price-candle-duration',
  get: ({ get }) => {
    const settings = get(userSettingsState);
    return settings?.oi?.candleDuration ?? 5 * 60;
  },
});

export const oiSelectedLenseState = selector<IntradayGammaLense>({
  key: 'oi-selected-filters-state',
  get: ({ get }) => {
    const settings = get(userSettingsState);
    return settings?.oi?.selectedLense ?? IntradayGammaLense.GAMMA;
  },
});

export const oiShowKeyLevelsState = selector<boolean>({
  key: 'oi-show-levels-state',
  get: ({ get }) => {
    const settings = get(userSettingsState);
    return settings?.oi?.hideLevels != null ? !settings?.oi?.hideLevels : true;
  },
});

export const oiShowColorScaleState = selector<boolean>({
  key: 'oi-show-color-scale-state',
  get: ({ get }) => {
    const fullscreen = get(oiFullscreenState);
    if (fullscreen) {
      return false;
    }
    const settings = get(userSettingsState);
    return settings?.oi?.hideColorScale != null
      ? !settings?.oi?.hideColorScale
      : true;
  },
});

export const oiScaleRangeState = selector<OIScaleRange>({
  key: 'oi-color-scale-min-max-state',
  get: ({ get }) => {
    const settings = get(userSettingsState);
    return settings?.oi?.scaleRange ?? OIScaleRange.AUTO;
  },
});

export const oiShowGexZeroDteState = selector<boolean>({
  key: 'oi-show-gex-zero-dte',
  get: ({ get }) => {
    const settings = get(userSettingsState);
    return settings?.oi?.showGexZeroDte != null
      ? settings?.oi?.showGexZeroDte
      : false;
  },
});

export const oiHiroSymsState = selector<Map<string, string>>({
  key: 'oi-hiro-syms-state',
  get: ({ get }) => {
    const settings = get(userSettingsState);
    return settings?.oi?.hiroSyms
      ? new Map(Object.entries(settings?.oi?.hiroSyms))
      : new Map([['SPX', HEATMAP_DEFAULT_SPX_HIRO_SYM]]);
  },
});

export const oiStrikeBarTypeState = selector<IntradayStrikeBarType>({
  key: 'oi-strike-bar-type-state',
  get: ({ get }) => {
    const settings = get(userSettingsState);
    return settings?.oi?.strikeBarType ?? IntradayStrikeBarType.GAMMA;
  },
});

export const oiShowContourLinesState = selector<boolean>({
  key: 'oi-show-contour-lines-state',
  get: ({ get }) => {
    const settings = get(userSettingsState);
    return settings?.oi?.hideContourLines != null
      ? !settings?.oi?.hideContourLines
      : true;
  },
});

export const oiFullscreenState = atom<boolean>({
  key: 'oi-fullscreen-state',
  default: false,
});

export const oiStatsLookbackDaysState = selector<number>({
  key: 'oi-stats-lookback-days-state',
  get: ({ get }) => {
    const settings = get(userSettingsState);
    return settings?.oi?.statsLookbackDays ?? 30;
  },
});

export const oiUseWhiteMode = selector<boolean>({
  key: 'oi-use-white-mode-state',
  get: ({ get }) => {
    const settings = get(userSettingsState);
    return settings?.oi?.useWhiteMode != null
      ? settings?.oi?.useWhiteMode
      : false;
  },
});

export const oiStrikeBarsTrackerEnabledState = selector<boolean>({
  key: 'oi-strike-bars-tracker-enabled-state',
  get: ({ get }) => {
    const settings = get(userSettingsState);
    return settings?.oi?.strikeBarsTrackerDisabled != null
      ? !settings?.oi?.strikeBarsTrackerDisabled
      : true;
  },
});

export const oiLatestServerTsMapState = atom<Map<string, number>>({
  key: 'oi-latest-server-ts-map-state',
  default: new Map(),
});
