import React from 'react';
import { Box, useTheme } from '@mui/material';
import { ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { PCFlowSum, PCFlowSumColors } from 'types/optionsFeed';
import { DEFAULT_FLOW_SUM_COLORS } from 'config/optionsFeed';
import { formatAsCompactNumber } from 'util/shared';

const RADIAN = Math.PI / 180;

interface RatioPieChartProps {
  data: PCFlowSum;
  colors?: PCFlowSumColors;
  useCustomLabels?: boolean;
}

type FlowType = keyof PCFlowSum;

const RatioPieChart: React.FC<RatioPieChartProps> = ({
  data,
  colors = DEFAULT_FLOW_SUM_COLORS,
  useCustomLabels = false,
}) => {
  const theme = useTheme();
  // Determine whether to show the 'total' slice
  const showTotal: boolean = data.call !== null && data.put !== null;

  // Prepare pie data excluding 'total'
  const pieData = (['put', 'call'] as const)
    .filter((key) => data[key] !== null && data[key] !== undefined)
    .map((key) => ({
      name: key as FlowType,
      value: data[key] as number, // since we filtered out nulls
      color: colors[key],
    }));

  // Prepare total data if both 'put' and 'call' are present
  const totalData: { name: FlowType; value: number }[] = showTotal
    ? [
        {
          name: 'total',
          value: data.total,
        },
      ]
    : [];

  // Custom label renderer for inner pies ('put' and 'call')
  const renderInnerLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    value,
  }: any) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.3;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill={theme.palette.text.primary}
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
        fontSize={11}
      >
        {formatAsCompactNumber(value)}
      </text>
    );
  };

  // Custom label renderer for the outer pie ('total')
  const renderTotalLabel = ({ cx, cy, midAngle, outerRadius, value }: any) => {
    const RADIAN = Math.PI / 180;
    const labelRadius = outerRadius + 10; // Offset for label placement
    const labelX = cx + labelRadius * Math.cos(-midAngle * RADIAN);
    const labelY = cy + labelRadius * Math.sin(-midAngle * RADIAN);
    const lineEndX = cx + outerRadius * Math.cos(-midAngle * RADIAN);
    const lineEndY = cy + outerRadius * Math.sin(-midAngle * RADIAN);

    return (
      <g>
        <line
          x1={lineEndX}
          y1={lineEndY}
          x2={labelX}
          y2={labelY}
          stroke="gray"
          strokeWidth={1}
        />
        <text
          x={labelX}
          y={labelY}
          fill={theme.palette.text.primary}
          textAnchor={labelX > cx ? 'start' : 'end'}
          dominantBaseline="central"
          fontSize={11}
        >
          {formatAsCompactNumber(value)}
        </text>
      </g>
    );
  };

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart
          margin={{
            left: 20,
            right: 20,
            bottom: 5,
          }}
        >
          <Pie
            data={pieData}
            cx="50%"
            cy="50%"
            outerRadius={showTotal ? '45%' : '70%'}
            dataKey="value"
            label={useCustomLabels ? renderInnerLabel : undefined}
            labelLine={false}
          >
            {pieData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
          {showTotal && (
            <Pie
              data={totalData}
              cx="50%"
              cy="50%"
              outerRadius="65%"
              innerRadius="50%"
              dataKey="value"
              label={useCustomLabels ? renderTotalLabel : undefined}
              labelLine={false}
            >
              <Cell fill={colors.total} />
            </Pie>
          )}
        </PieChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default RatioPieChart;
