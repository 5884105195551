import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  Stack,
  Typography,
} from '@mui/material';
import SettingsPopout from '../shared/SettingsPopout';
import InvertColorsIcon from '@mui/icons-material/InvertColors';
import { SGTooltip } from '../core';
import { MuiColorInput } from 'mui-color-input';
import { IntradayStrikeBarType, TraceParams, TraceSettings } from '../../types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IntradayFiltersReadable } from '../../config';
import useUserDetails from '../../hooks/user/useUserDetails';
import { useRecoilValue } from 'recoil';
import { oiStrikeBarTypeState, userSettingsState } from '../../states';
import { isValidHex } from '../../util';
import useToast from '../../hooks/useToast';

type TraceColorSettingsProps = {
  traceParams: TraceParams;
};

export const TraceColorSettings = ({
  traceParams,
}: TraceColorSettingsProps) => {
  const settings = useRecoilValue(userSettingsState);
  const strikeBarType = useRecoilValue(oiStrikeBarTypeState);

  const { saveSgSettings } = useUserDetails();
  const { openToast } = useToast();
  const { selectedLense, selectedTheme, heatmapColorSettings } = traceParams;
  const saveChanges = (newSettings: Partial<TraceSettings>, value?: string) => {
    if (value != null && !isValidHex(value)) {
      openToast({
        message: 'Invalid color supplied. Please try again with a valid color.',
        type: 'error',
      });
      return;
    }
    saveSgSettings({ oi: newSettings });
  };

  const resetCustomLenseColors = () =>
    saveChanges({
      customColorsPerLense: {
        ...(settings.oi?.customColorsPerLense ?? {}),
        [selectedLense]: null,
      },
    });

  const resetAllColors = () => {
    saveChanges({
      customColorsPerLense: {
        ...(settings.oi?.customColorsPerLense ?? {}),
        [selectedLense]: null,
      },
      hiroColor: null,
      posColor: null,
      negColor: null,
      posStrikeColor: null,
      negStrikeColor: null,
      downCandleLineColor: null,
      upCandleLineColor: null,
      upCandleFill: null,
      downCandleFill: null,
      putColor: null,
      callColor: null,
      contourLineColor: null,
    });
  };

  const customColorsForSelectedLense =
    settings?.oi?.customColorsPerLense?.[selectedLense];
  return (
    <Box sx={{ height: '40px', alignSelf: 'center' }}>
      <SettingsPopout
        title="TRACE Color Settings"
        popperID={'trace-color-settings'}
        zIndex={2}
        placement="left-start"
        icon={<InvertColorsIcon />}
      >
        <Stack direction="row">
          <Box display="flex" flexDirection="column" gap="6px">
            <SGTooltip
              placement={'top'}
              title={
                'This color is used as the default color for positive values on the heatmap'
              }
            >
              <Typography variant="h4" gutterBottom color="primary">
                Positive Heatmap Color
              </Typography>
            </SGTooltip>

            <MuiColorInput
              format="hex8"
              value={
                settings.oi?.posColor ??
                selectedTheme.palette.trace.heatmapSettings.posHeatmapColor
              }
              onChange={(value: string) =>
                saveChanges(
                  {
                    posColor: value,
                  },
                  value,
                )
              }
              PopoverProps={{
                anchorOrigin: {
                  horizontal: 'left',
                  vertical: 'bottom',
                },
              }}
            />
          </Box>

          <Box display="flex" flexDirection="column" gap="6px">
            <SGTooltip
              placement={'top'}
              title={
                'This color is used as the default color for negative values on the heatmap'
              }
            >
              <Typography variant="h4" gutterBottom color="primary">
                Negative Heatmap Color
              </Typography>
            </SGTooltip>
            <MuiColorInput
              format="hex8"
              value={
                settings.oi?.negColor ??
                selectedTheme.palette.trace.heatmapSettings.negHeatmapColor
              }
              onChange={(value: string) =>
                saveChanges(
                  {
                    negColor: value,
                  },
                  value,
                )
              }
              PopoverProps={{
                anchorOrigin: {
                  horizontal: 'right',
                  vertical: 'bottom',
                },
              }}
            />
          </Box>
        </Stack>

        {strikeBarType !== IntradayStrikeBarType.NONE && (
          <Accordion sx={{ marginY: '3px !important' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />}>
              <Typography>
                <h4>Strike Plot Color Settings</h4>
              </Typography>
            </AccordionSummary>

            <Stack direction="row">
              <Box display="flex" flexDirection="column" gap="6px">
                <SGTooltip
                  placement={'left'}
                  title={`This color is used as the default color for positive values on the strike plot when GEX is selected`}
                >
                  <Typography variant="h4" gutterBottom color="primary">
                    Positive GEX Color
                  </Typography>
                </SGTooltip>

                <MuiColorInput
                  format="hex8"
                  value={heatmapColorSettings.posStrikeColor}
                  onChange={(value: string) =>
                    saveChanges(
                      {
                        posStrikeColor: value,
                      },
                      value,
                    )
                  }
                  PopoverProps={{
                    anchorOrigin: {
                      horizontal: 'left',
                      vertical: 'bottom',
                    },
                  }}
                />
              </Box>

              <Box display="flex" flexDirection="column" gap="6px">
                <SGTooltip
                  placement={'left'}
                  title={`This color is used as the default color for negative values on the strike bars when GEX is selected`}
                >
                  <Typography variant="h4" gutterBottom color="primary">
                    Negative GEX Color
                  </Typography>
                </SGTooltip>
                <MuiColorInput
                  format="hex8"
                  value={heatmapColorSettings.negStrikeColor}
                  onChange={(value: string) =>
                    saveChanges(
                      {
                        negStrikeColor: value,
                      },
                      value,
                    )
                  }
                  PopoverProps={{
                    anchorOrigin: {
                      horizontal: 'right',
                      vertical: 'bottom',
                    },
                  }}
                />
              </Box>
            </Stack>

            <Stack direction="row">
              <Box display="flex" flexDirection="column" gap="6px">
                <SGTooltip
                  placement={'left'}
                  title={`This color is used as the default color for call values on the strike plot when OI or Net OI are selected`}
                >
                  <Typography variant="h4" gutterBottom color="primary">
                    OI Calls Color
                  </Typography>
                </SGTooltip>

                <MuiColorInput
                  format="hex8"
                  value={heatmapColorSettings.callColor}
                  onChange={(value: string) =>
                    saveChanges({ callColor: value }, value)
                  }
                  PopoverProps={{
                    anchorOrigin: {
                      horizontal: 'left',
                      vertical: 'bottom',
                    },
                  }}
                />
              </Box>

              <Box display="flex" flexDirection="column" gap="6px">
                <SGTooltip
                  placement={'left'}
                  title={`This color is used as the default color for put values on the strike bars when OI or Net OI are selected`}
                >
                  <Typography variant="h4" gutterBottom color="primary">
                    OI Puts Color
                  </Typography>
                </SGTooltip>
                <MuiColorInput
                  format="hex8"
                  value={heatmapColorSettings.putColor}
                  onChange={(value: string) =>
                    saveChanges({ putColor: value }, value)
                  }
                  PopoverProps={{
                    anchorOrigin: {
                      horizontal: 'right',
                      vertical: 'bottom',
                    },
                  }}
                />
              </Box>
            </Stack>
          </Accordion>
        )}

        <Accordion sx={{ marginY: '3px !important' }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />}>
            <Typography>
              <h4>HIRO and Contour Color Settings</h4>
            </Typography>
          </AccordionSummary>
          <Stack direction="row">
            <Box display="flex" flexDirection="column" gap="6px">
              <SGTooltip
                title={'The color of the HIRO line on the heatmap'}
                placement="left"
              >
                <Typography variant="h4" gutterBottom color="primary">
                  HIRO Line Color
                </Typography>
              </SGTooltip>
              <MuiColorInput
                format="hex8"
                value={heatmapColorSettings.hiroColor}
                onChange={(value: string) =>
                  saveChanges(
                    {
                      hiroColor: value,
                    },
                    value,
                  )
                }
                PopoverProps={{
                  anchorOrigin: {
                    horizontal: 'left',
                    vertical: 'bottom',
                  },
                }}
              />
            </Box>

            <Box display="flex" flexDirection="column" gap="6px">
              <SGTooltip
                title={'The color of the contour lines on the heatmap'}
                placement="left"
              >
                <Typography variant="h4" gutterBottom color="primary">
                  Contour Lines Color
                </Typography>
              </SGTooltip>
              <MuiColorInput
                format="hex8"
                value={heatmapColorSettings.contourLineColor}
                onChange={(value: string) =>
                  saveChanges(
                    {
                      contourLineColor: value,
                    },
                    value,
                  )
                }
                PopoverProps={{
                  anchorOrigin: {
                    horizontal: 'left',
                    vertical: 'bottom',
                  },
                }}
              />
            </Box>
          </Stack>
        </Accordion>

        <Accordion sx={{ marginY: '3px !important' }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />}>
            <Typography>
              <h4>Candle Color Settings</h4>
            </Typography>
          </AccordionSummary>
          <Stack direction="row">
            <Box display="flex" flexDirection="column" gap="6px">
              <SGTooltip
                title={
                  'The color of the borders of up/positive price candles on the heatmap'
                }
                placement="left"
              >
                <Typography variant="h4" gutterBottom color="primary">
                  Up Candle Border Color
                </Typography>
              </SGTooltip>
              <MuiColorInput
                format="hex8"
                value={heatmapColorSettings.candleUpLineColor}
                onChange={(value: string) =>
                  saveChanges(
                    {
                      upCandleLineColor: value,
                    },
                    value,
                  )
                }
                PopoverProps={{
                  anchorOrigin: {
                    horizontal: 'left',
                    vertical: 'bottom',
                  },
                }}
              />
            </Box>

            <Box display="flex" flexDirection="column" gap="6px">
              <SGTooltip
                title={
                  'The color of the borders of down/negative price candles on the heatmap'
                }
                placement="left"
              >
                <Typography variant="h4" gutterBottom color="primary">
                  Down Candle Border Color
                </Typography>
              </SGTooltip>
              <MuiColorInput
                format="hex8"
                value={heatmapColorSettings.candleDownLineColor}
                onChange={(value: string) =>
                  saveChanges(
                    {
                      downCandleLineColor: value,
                    },
                    value,
                  )
                }
                PopoverProps={{
                  anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'bottom',
                  },
                }}
              />
            </Box>
          </Stack>

          <Stack direction="row">
            <Box display="flex" flexDirection="column" gap="6px">
              <SGTooltip
                title={
                  'The color of the inside body of up/positive price candles on the heatmap'
                }
                placement="left"
              >
                <Typography variant="h4" gutterBottom color="primary">
                  Up Candle Fill Color
                </Typography>
              </SGTooltip>
              <MuiColorInput
                format="hex8"
                value={heatmapColorSettings.candleUpFillColor}
                onChange={(value: string) =>
                  saveChanges(
                    {
                      upCandleFill: value,
                    },
                    value,
                  )
                }
                PopoverProps={{
                  anchorOrigin: {
                    horizontal: 'left',
                    vertical: 'bottom',
                  },
                }}
              />
            </Box>

            <Box display="flex" flexDirection="column" gap="6px">
              <SGTooltip
                title={
                  'The color of the inside body of down/negative price candles on the heatmap'
                }
                placement="left"
              >
                <Typography variant="h4" gutterBottom color="primary">
                  Down Candle Fill Color
                </Typography>
              </SGTooltip>
              <MuiColorInput
                format="hex8"
                value={heatmapColorSettings.candleDownFillColor}
                onChange={(value: string) =>
                  saveChanges(
                    {
                      downCandleFill: value,
                    },
                    value,
                  )
                }
                PopoverProps={{
                  anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'bottom',
                  },
                }}
              />
            </Box>
          </Stack>
        </Accordion>

        <Accordion
          sx={{ marginTop: '3px !important', marginBottom: '10px !important' }}
          defaultExpanded={customColorsForSelectedLense != null}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />}>
            <SGTooltip
              placement="left"
              title={`Set colors that only apply when you have ${IntradayFiltersReadable.get(
                selectedLense,
              )!} selected`}
            >
              <Typography>
                <h4>
                  Colors For {IntradayFiltersReadable.get(selectedLense)!} Only
                </h4>
              </Typography>
            </SGTooltip>
          </AccordionSummary>

          <Stack direction="row">
            <Box display="flex" flexDirection="column" gap="6px">
              <SGTooltip
                placement={'left'}
                title={`This will override your default positive heatmap color, but only when ${IntradayFiltersReadable.get(
                  selectedLense,
                )!} is selected and only for the heatmap.`}
              >
                <Typography variant="h4" gutterBottom color="primary">
                  Positive Heatmap Color
                </Typography>
              </SGTooltip>
              <MuiColorInput
                format="hex8"
                value={heatmapColorSettings.posHeatmapColor}
                onChange={(value: string) =>
                  saveChanges(
                    {
                      customColorsPerLense: {
                        ...(settings?.oi?.customColorsPerLense ?? {}),
                        [selectedLense]: {
                          ...(customColorsForSelectedLense ?? {}),
                          posHeatmapColor: value,
                        },
                      },
                    },
                    value,
                  )
                }
                PopoverProps={{
                  anchorOrigin: {
                    horizontal: 'left',
                    vertical: 'bottom',
                  },
                }}
              />
            </Box>

            <Box display="flex" flexDirection="column" gap="6px">
              <SGTooltip
                placement={'left'}
                title={`This will override your default negative heatmap color, but only when ${IntradayFiltersReadable.get(
                  selectedLense,
                )!} is selected and only for the heatmap.`}
              >
                <Typography variant="h4" gutterBottom color="primary">
                  Negative Heatmap Color
                </Typography>
              </SGTooltip>
              <MuiColorInput
                format="hex8"
                value={heatmapColorSettings.negHeatmapColor}
                onChange={(value: string) =>
                  saveChanges(
                    {
                      customColorsPerLense: {
                        ...(settings?.oi?.customColorsPerLense ?? {}),
                        [selectedLense]: {
                          ...(customColorsForSelectedLense ?? {}),
                          negHeatmapColor: value,
                        },
                      },
                    },
                    value,
                  )
                }
                PopoverProps={{
                  anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'bottom',
                  },
                }}
              />
            </Box>
          </Stack>
        </Accordion>

        <Stack direction="row" justifyContent="space-between">
          <SGTooltip
            placement="bottom"
            title={'Reset all color settings to their SpotGamma defaults'}
          >
            <Button
              onClick={resetAllColors}
              sx={{
                textTransform: 'none',
                fontSize: '13px',
              }}
            >
              Reset All
            </Button>
          </SGTooltip>

          {customColorsForSelectedLense != null && (
            <SGTooltip
              placement="bottom"
              title={`Remove the custom colors specified under 'Colors for ${IntradayFiltersReadable.get(
                selectedLense,
              )!} only'`}
            >
              <Button
                onClick={resetCustomLenseColors}
                sx={{
                  textTransform: 'none',
                  fontSize: '13px',
                }}
              >
                Reset {IntradayFiltersReadable.get(selectedLense)!} Colors
              </Button>
            </SGTooltip>
          )}
        </Stack>
      </SettingsPopout>
    </Box>
  );
};
